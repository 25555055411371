import { ABOVE_LIMIT_TAX, PERCENTILE, TAX, TDS } from "../config";

let characters = '';
let passwordLength = 10;
let grossSalary = 0
characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()<>,.?/[]{}-=_+|/0123456789';


export const taxCalculation = (employeeData) => {
    const { gst, gender, salary, tds, type_of_employee } = employeeData
    if (type_of_employee === "consultant") {
        if (salary === ' ' || !salary || salary <= 1) return { ...employeeData, gross_salary: 0, tds: 0, gst: 0, }
        return { ...employeeData, gross_salary: salary - gst - (TDS / PERCENTILE) * salary, tds: (TDS / PERCENTILE) * salary }
    } else if (type_of_employee === "employee") {
        if (salary === ' ' || !salary || salary <= 1) return { ...employeeData, gross_salary: 0, professional_tax: 0 }
        if (gender === "male") {
            if (salary <= 7500) return { ...employeeData, professional_tax: 0, gross_salary: salary }
            if (salary >= 7501 && salary <= 10000) return { ...employeeData, professional_tax: TAX, gross_salary: salary - TAX - tds }
            if (salary >= 10001) return { ...employeeData, professional_tax: ABOVE_LIMIT_TAX, gross_salary: salary - ABOVE_LIMIT_TAX - tds }
            return { ...employeeData, professional_tax: TAX, gross_salary: grossSalary }
        } else if (gender === "female") {
            if (salary <= 25000) return { ...employeeData, professional_tax: 0, gross_salary: salary }
            grossSalary = salary
            if (salary >= 25001) return { ...employeeData, professional_tax: ABOVE_LIMIT_TAX, gross_salary: salary - ABOVE_LIMIT_TAX - tds }
        }
    }
    return { ...employeeData }
}




export const generatePassword = (passwordProps) => {
    const { uppercase, lowercase, symbols, numbers, passLen = passwordLength } = passwordProps;
    setUpperCase(uppercase);
    setLowerCase(lowercase);
    setSymbols(symbols);
    setNumber(numbers);
    const password = passwordCharacters(passLen);
    return password;
}

const setUpperCase = isUpperCase => {
    if (isUpperCase) {
        characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    return '';
}

const setLowerCase = isLowerCase => {
    if (isLowerCase) {
        characters += 'abcdefghijklmnopqrstuvwxyz';
    }
    return '';
}

const setSymbols = isSymbol => {
    if (isSymbol) {
        characters += '!@#$%^&*()<>,.?/[]{}-=_+|/';
    }
    return '';
}

const setNumber = isNumeric => {
    if (isNumeric) {
        characters += '0123456789';
    }
    return '';
}

const getRandomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const passwordCharacters = (passLen) => {
    let password = '';
    if (characters.length) {
        for (let i = 0; i < passLen; i++) {
            password += characters[getRandomInteger(0, characters.length - 1)];
        }
        characters = '';
        return password;
    }
}

export const setPasswordLength = length => {
    passwordLength = length;
    return passwordLength;
}

export const formatDateForMonth = (day, dateObject) => {
    let month;
    if (dateObject.getMonth() + 1 < 10) {
        month = '0' + (dateObject.getMonth() + 1);
    }
    else {
        month = dateObject.getMonth() + 1;
    }
    if (day < 10) {
        return '0' + day + "-" + (month) + "-" + (dateObject.getUTCFullYear())
    }
    else {
        return day + "-" + (month) + "-" + (dateObject.getUTCFullYear())
    }
}


export const formatTime = (timeString) => {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}

// Function returns "," for number values
export const numberWithComma = (value) => {
    if(!value) return 
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export const generateURL = (obj) => {
    // Initialize an array to hold key-value pairs
    const keyValuePairs = [];
  
    // Loop through the object's properties
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Encode the key and value and add them to the array
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(obj[key]);
        keyValuePairs.push(`${encodedKey}=${encodedValue}`);
      }
    }
  
    // Join the key-value pairs with "&" and add them to the base URL
    const queryString = keyValuePairs.join('&');
    const url = `?${queryString}`;
  
    return url;
  }