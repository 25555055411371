import toast from "react-hot-toast";
import usePut from "../usePut"
import { throwError } from "../../utility/errorHandling";

const useBulkUploadAttendance = ({
    onSuccess
}) => {
    const { handleAuth: handlePost } = usePut({
        url: 'excelUploadAttendance',
        refreshUrl: 'employeelist',
        onSuccess: (res) => {
            onSuccess?.()
            toast.success('Attendance uploaded successfully')
        },
        onError: (err) => {
            // console.log(err);
            throwError(err)
        }
    })
    const handleUploadAttendance = (data) => {
        handlePost.mutate(data)
    }
    return { handleUploadAttendance, handlePost }
}

export default useBulkUploadAttendance