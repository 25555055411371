import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

const IS_REQUIRED = true
const AddressForm = forwardRef(({ addressData }, ref) => {
    const addressInitialState = {
        permanent_address_1: "",
        permanent_address_2: "",
        permanent_pincode: "",
        permanent_state: "",
        permanent_city: "",
        address_1: "",
        address_2: "",
        pincode: "",
        state: "",
        city: "",
        isAddressSame: false
    }
    useImperativeHandle(ref, (() => ({
        getAddressData: () => {
            return addressState
        }
    })))
    const [addressState, setAddressState] = useState(addressInitialState)
    const handleSetAddress = (e) => {
        let value = e.target.value
        let name = e.target.name
        setAddressState((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const handleChangeSameAddress = (e) => {
        let checked = e.target.checked
        if (checked) {
            setAddressState((prev) => {
                return {
                    ...prev,
                    address_1: addressState.permanent_address_1,
                    address_2: addressState.permanent_address_2,
                    pincode: addressState.permanent_pincode,
                    state: addressState.permanent_state,
                    city: addressState.permanent_city,
                }
            })
        } else {
            setAddressState((prev) => {
                return {
                    ...prev,
                    address_1: "",
                    address_2: "",
                    pincode: "",
                    state: "",
                    city: "",
                }
            })
        }
        setAddressState((prev) => {
            return {
                ...prev,
                isAddressSame: checked
            }
        })
    }
    useEffect(() => {
        if (addressData) {
            Object.keys(addressInitialState).forEach((key) => {
                setAddressState((prev) => {
                    return {
                        ...prev,
                        [key]: addressData[key]
                    }
                })
            })
        }
    }, [addressData])
    return (
        <div className='grid grid-cols-2 gap-x-4'>
            <div className=' space-y-3'>
                <h1 className='mt-8 text-2xl font-semibold'>Permanent Address Details</h1>
                <div className="">
                    <label htmlFor="address_1" className='label'>Address Line 1:<span className='mandatory'>*</span></label>
                    <input type="text" name='permanent_address_1' className='input' required={IS_REQUIRED} value={addressState.permanent_address_1} onChange={handleSetAddress} placeholder='Enter address line 1 code' />
                </div>
                <div className="">
                    <label htmlFor="address_2" className='label'>Address Line 2:</label>
                    <input type="text" name='permanent_address_2' className='input' value={addressState.permanent_address_2} onChange={handleSetAddress} placeholder='Enter address line 2 code' />
                </div>
                <div className="">
                    <label htmlFor="pincode" className='label'>Pincode:<span className='mandatory'>*</span></label>
                    <input type="text" name='permanent_pincode' className='input' required={IS_REQUIRED} value={addressState.permanent_pincode} onChange={handleSetAddress} placeholder='Pincode' />
                </div>
                <div className="">
                    <label htmlFor="city" className='label'>City:<span className='mandatory'>*</span></label>
                    <input type="text" name='permanent_city' className='input' required={IS_REQUIRED} value={addressState.permanent_city} onChange={handleSetAddress} placeholder='Enter city ' />
                </div>
                <div className="">
                    <label htmlFor="city" className='label'>State:<span className='mandatory'>*</span></label>
                    <input type="text" name='permanent_state' className='input' required={IS_REQUIRED} value={addressState.permanent_state} onChange={handleSetAddress} placeholder='Enter city ' />
                </div>
            </div>
            <div className=' space-y-3'>
                <div className='flex items-center justify-between mt-8 space-x-2'>
                    <h1 className='text-2xl font-semibold'>Temporary Address Details</h1>
                    <div className=' space-x-2 flex items-center'>
                        <input type="checkbox" id='isAddressSame' checked={addressState.isAddressSame} name='isAddressSame' onChange={handleChangeSameAddress} />
                        <label htmlFor='isAddressSame' className=' cursor-pointer text-xs'>Same As Permanent Address</label>
                    </div>
                </div>
                <div className="">
                    <label htmlFor="address_1" className='label'>Address Line 1:<span className='mandatory'></span></label>
                    <input type="text" name='address_1' className='input' value={addressState.address_1} onChange={handleSetAddress} placeholder='Enter address line 1 code' />
                </div>
                <div className="">
                    <label htmlFor="address_2" className='label'>Address Line 2:</label>
                    <input type="text" name='address_2' className='input' value={addressState.address_2} onChange={handleSetAddress} placeholder='Enter address line 2 code' />
                </div>
                <div className="">
                    <label htmlFor="pincode" className='label'>Pincode:<span className='mandatory'></span></label>
                    <input type="text" name='pincode' className='input' value={addressState.pincode} onChange={handleSetAddress} placeholder='Pincode' />
                </div>
                <div className="">
                    <label htmlFor="city" className='label'>City:<span className='mandatory'></span></label>
                    <input type="text" name='city' className='input' value={addressState.city} onChange={handleSetAddress} placeholder='Enter city ' />
                </div>
                <div className="">
                    <label htmlFor="city" className='label'>State:<span className='mandatory'></span></label>
                    <input type="text" name='state' className='input' value={addressState.state} onChange={handleSetAddress} placeholder='Enter city ' />
                </div>
            </div>
        </div>
    )
})

export default AddressForm