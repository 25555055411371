import { XIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'

const ToastMessage = ({ open, variant, message }) => {
    const VARIANT_LIST = {
        success: 'bg-green-100 text-green-600',
        error: 'bg-red-100 text-red-600',
    }
    const [isToastOpen, setIsToastOpen] = useState(open)
    useEffect(() => {
        setIsToastOpen(open)
    },[open])
    return (
        <div className={`py-4 my-4 px-5 w-full font-semibold text-base ${VARIANT_LIST[variant]} ${isToastOpen ? 'block' : ' hidden'}`}>
            <div className='flex items-center justify-between'>
                <p>{message || 'Toast Message'}</p>
                <div className=' cursor-pointer' onClick={() => setIsToastOpen(false)}>
                    <XIcon className='w-6 h-6 ' />
                </div>
            </div>
        </div>
    )
}

export default ToastMessage