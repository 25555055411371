import { useMutation } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { TOKEN, config } from "../../config";
import { axiosPrivate } from "../../api/newAxios";

const cookies = new Cookies();
const useReadWithPost = ({
  url,
  onSuccess
}) => {
  const postData = useMutation({
    mutationFn: (data) => handleFormSubmit(data),
    onSuccess: (data) => {
      onSuccess?.(data)
    },
    onError: (err) => {
      // onError?.(err)
    },
  })
  const handleFormSubmit = async (getFormData) => {
    let token = cookies.get(TOKEN);
    let fd = new FormData()
    for (var key in getFormData) {
      if (Array.isArray(getFormData[key])) {
        fd.append(key, JSON.stringify(getFormData[key]));
      } else {
        fd.append(key, getFormData[key]);
      }
    }

    const response = await axiosPrivate({
      method: 'POST',
      url: `/${url}`,
      data: fd,
      headers: { Authorization: token ? `Bearer ${token}` : '', }
    })
    return response
  }
  return { postData }
}

export default useReadWithPost