import React, { useEffect, useState } from 'react'
import Layout from '../../../components/layout/Layout'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { Link, useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import usePut from '../../../hooks/usePut'
import spinner from '../../../assets/images/spinner.gif'
import toast, { Toaster } from 'react-hot-toast'

const EmployeeSalaryDetails = () => {
    const [searchParams] = useSearchParams();
    const initialSalaryState = {
        basic_monthly: '',
        basic_yearly: '',
        hra_monthly: '',
        hra_yearly: '',
        conveyance_monthly: '',
        conveyance_yearly: '',
        other_allowance_monthly: '',
        other_allowance_yearly: '',
        monthly_ctc: '',
        annual_ctc: '',
        variable_fixed_monthly: '',
        variable_fixed_yearly: '',
        from_date: new Date()
    }
    const [salaryForm, setSalaryForm] = useState(initialSalaryState)
    const { id, username } = useParams()
    const location = useLocation()
    useEffect(() => {
        if (location.state) {
            // get the state from the previous location / screen
            const originalDate = new Date(location.state.from_date * 1000)
            setSalaryForm({
                ...location.state,
                from_date: `${originalDate.getFullYear()}-${String(originalDate.getMonth() + 1).padStart(2, '0')}-${String(originalDate.getDate()).padStart(2, '0')}`
            })
        }
    }, [location])
    const navigate = useNavigate()
    const handleChangeDateDetail = (e) => {
        let value = e.target.value
        let name = e.target.name
        setSalaryForm(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const calculateSalary = () => {
        let calculatedSalary = {
            ...salaryForm,
            basic_yearly: salaryForm.basic_monthly * 12,
            hra_yearly: salaryForm.hra_monthly * 12,
            conveyance_yearly: salaryForm.conveyance_monthly * 12,
            other_allowance_yearly: salaryForm.other_allowance_monthly * 12,
            variable_fixed_yearly: salaryForm.variable_fixed_monthly * 12,
            monthly_ctc: salaryForm.basic_monthly + salaryForm.hra_monthly + salaryForm.conveyance_monthly + salaryForm.other_allowance_monthly + salaryForm.variable_fixed_monthly,
            annual_ctc: salaryForm.basic_yearly + salaryForm.hra_yearly + salaryForm.conveyance_yearly + salaryForm.other_allowance_yearly + salaryForm.variable_fixed_yearly,
        }
        setSalaryForm({...calculatedSalary})
    }
    const handleChangeCents = (e) => {
        let value = +e.target.value
        let name = e.target.name
        setSalaryForm(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    function getEsicAmount(monthly_ctc) {
        var esic_percentage = 3.25;
        return Math.ceil((monthly_ctc * (esic_percentage / (esic_percentage + 100))));
    }
    const calculateData = () => {
        calculateSalary()
    }
    const { handleAuth } = usePut({
        url: 'createEmployeeSalary',
        onSuccess: () => {
            navigate('/employe-details')
        }
    })
    const updateEmployeeSalary = usePut({
        url: 'updateEmployeeSalary',
        onSuccess: () => {
            toast.success('Salary Updated Successfully')
        },
        onError: () => {
            toast.error('There was an error while updating salary')
        }
    })
    const handleSaveSalary = () => {
        let annualCTC = salaryForm.basic_yearly + salaryForm.hra_yearly + salaryForm.conveyance_yearly + salaryForm.other_allowance_yearly + salaryForm.variable_fixed_yearly
        if (!salaryForm.from_date || !annualCTC) {
            return alert('Please Enter All The Details: "ANNUAL CTC" and "EFFECT FROM DATE" ')
        }
        let data = {
            ...salaryForm,
            salary_id: salaryForm.salary_id || null,
            employee_id: id,
            annual_ctc: annualCTC,
            basic_percentage: salaryForm.basic_ctc_cent,
            hra_percentage: salaryForm.hra_cent,
            conveyance_monthly: salaryForm.conveyance_monthly,
            from_date: Math.floor(new Date(salaryForm.from_date).getTime() / 1000)
        }
        // console.log({data});
        handleAuth.mutate(data)
    }

    const handleUpdateSalary = () => {
        let data = {
            ...salaryForm,
            employee_id: id,
        }
        updateEmployeeSalary.handleAuth.mutate(data)
    }
    return (
        <Layout>
            <Toaster/>
            {handleAuth.isLoading &&
                <div className=' fixed top-0 left-0 z-50 w-full h-full bg-[#ffffffa1] flex items-center justify-center'>
                    <img src={spinner} className='w-14 h-14 mx-auto' />
                </div>}
            <div className=' px-4 py-4 flex justify-between sticky bg-white top-16 shadow-lg'>
                <div className='flex items-center space-x-4'>
                    <button onClick={() => navigate(-1, { replace: true })} className='flex items-center hover:underline'>
                        <ChevronLeftIcon className='w-6 h-6' />
                        <span className='font-semibold'>Back</span>
                    </button>
                    {/* <h2 className='text-2xl font-semibold'>Salary Details for {username}</h2> */}
                </div>
                {searchParams.get('mode') !== 'resigned' 
                && <>
                    {salaryForm.salary_id
                        ? <button onClick={handleUpdateSalary} className='px-5 py-2 bg-yellow-300 rounded font-semibold'>Update Details</button>
                        :
                        <div className=' space-x-4'>
                            <button onClick={handleSaveSalary} className='px-5 py-2 bg-yellow-300 rounded font-semibold'>Save Details</button>
                            <Link to={`/employee-salary-details/${id}}`} className='px-5 py-2 bg-blue-100 rounded font-semibold'>View Salary</Link>
                        </div>}
                </>}
            </div>
            <div className='px-4 my-5'>
                <div className='grid grid-cols-3 gap-x-5'>
                    <div className="">
                        <label htmlFor="first_name" className='label'>Effect From:<span className='mandatory'>*</span></label>
                        <div>
                            <div className='flex items-center space-x-2'>
                                <input type="date" name='from_date' disabled={searchParams.get('mode') === 'resigned'} className='input' value={salaryForm.from_date} placeholder='Enter Annual CTC ' onChange={handleChangeDateDetail} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' px-10 border border-gray-200 mt-10 py-'>
                    <div className='grid grid-cols-8  gap-x-8 border-b border-gray-200 py-4'>
                        <div className=" col-span-2">
                            <label htmlFor="first_name" className='label'>SALARY COMPONENTS:</label>
                        </div>
                        <div className=" col-span-2">
                            <label htmlFor="first_name" className='label'>MONTHLY AMOUNT:</label>
                        </div>
                        <div className=" col-span-2 text-center">
                            <label htmlFor="first_name" className='label'>ANNUAL AMOUNT:</label>
                        </div>
                    </div>
                    <div className='space-y-8 pb-8'>
                        <div className='mt-5'>
                            <h2 className='font-semibold text-xl'>Earnings</h2>
                        </div>
                        <div className='grid grid-cols-8 mt-5 gap-x-8'>
                            <div className=" col-span-2">
                                <label htmlFor="first_name" className='label'>Basic:</label>
                            </div>
                            <div className=" col-span-2">
                                <div className='flex items-center space-x-2'>
                                    <input onWheel={(e) => e.target.blur()} type="number" disabled={searchParams.get('mode') === 'resigned'} name='basic_monthly' value={salaryForm.basic_monthly} onBlur={calculateData} onChange={handleChangeCents} className='input' placeholder='' />
                                </div>
                            </div>
                            <div className=" col-span-2 text-center">
                                <label htmlFor="first_name" className='label bg-gray-100 input h-[2.6rem]'>{salaryForm?.basic_yearly}</label>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 mt-5 gap-x-8'>
                            <div className=" col-span-2">
                                <label htmlFor="first_name" className='label'>House Rent Allowance:</label>
                            </div>
                            <div className=" col-span-2">
                                <div className='flex items-center space-x-2'>
                                    <input onWheel={(e) => e.target.blur()} type="number" disabled={searchParams.get('mode') === 'resigned'} name='hra_monthly' value={salaryForm.hra_monthly} onBlur={calculateData} onChange={handleChangeCents} className='input' placeholder='' />
                                </div>
                            </div>
                            <div className=" col-span-2 text-center">
                                <label onWheel={(e) => e.target.blur()} htmlFor="first_name" className='label bg-gray-100 input h-[2.6rem]'>{salaryForm.hra_yearly}</label>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 mt-5 gap-x-8'>
                            <div className=" col-span-2">
                                <label htmlFor="first_name" className='label'>Conveyance:</label>
                            </div>
                            <div className=" col-span-2">
                                <div className='flex items-center space-x-2'>
                                    <input onWheel={(e) => e.target.blur()} type="number" disabled={searchParams.get('mode') === 'resigned'} name='conveyance_monthly' value={salaryForm.conveyance_monthly} onBlur={calculateData} onChange={handleChangeCents} className='input' placeholder='' />
                                </div>
                            </div>
                            <div className=" col-span-2 text-center">
                                <label htmlFor="first_name" className='label bg-gray-100 input h-[2.6rem]'>{salaryForm.conveyance_yearly}</label>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 mt-5 gap-x-8'>
                            <div className=" col-span-2">
                                <label htmlFor="first_name" className='label'>Others:</label>
                            </div>
                            <div className=" col-span-2">
                                <input onWheel={(e) => e.target.blur()} type="number" disabled={searchParams.get('mode') === 'resigned'} name='other_allowance_monthly' value={salaryForm.other_allowance_monthly} onBlur={calculateData} onChange={handleChangeCents} className='input' placeholder='' />
                            </div>
                            <div className=" col-span-2 text-center">
                                <label htmlFor="first_name" className='label bg-gray-100 input h-[2.6rem]'>
                                    {salaryForm.other_allowance_yearly}
                                </label>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 mt-5 gap-x-8'>
                            <div className=" col-span-2">
                                <label htmlFor="first_name" className='label'>Variable OT:</label>
                            </div>
                            <div className=" col-span-2">
                                <input onWheel={(e) => e.target.blur()} type="number" disabled={searchParams.get('mode') === 'resigned'} name='variable_fixed_monthly' value={salaryForm.variable_fixed_monthly} onBlur={calculateData} onChange={handleChangeCents} className='input' placeholder='' />
                            </div>
                            <div className=" col-span-2 text-center">
                                <label htmlFor="first_name" className='label bg-gray-100 input h-[2.6rem]'>
                                    {salaryForm.variable_fixed_yearly}
                                </label>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 mt-5 gap-x-8 bg-blue-100 rounded -mx-4 px-4 py-2'>
                            <div className=" col-span-2">
                                <label htmlFor="first_name" className='label text-2xl font-semibold'>Cost to Company</label>
                            </div>
                            <div className=" col-span-2">
                                <label htmlFor="first_name" className='label text-xl'>&#8377; {salaryForm.monthly_ctc || 0}</label>
                            </div>
                            <div className=" col-span-2 text-center">
                                <label htmlFor="first_name" className='label text-xl'>&#8377; {salaryForm.annual_ctc || 0}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default EmployeeSalaryDetails