import React, { useState } from 'react'
import Salary from '..'
import DatePicker from "react-datepicker";
import { useNavigate, useSearchParams } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import useReadWithGet from '../../../hooks/read/useReadWithGetMethod';
import useDownloadFile from '../../../hooks/useDownloadFile';
import spinner from '../../../assets/images/spinner.gif';
import Pagination from '../../../components/pagination';

const ProvidentFund = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    let initialData = {
        month_year: searchParams.get('month_year') ? new Date(searchParams.get('month_year')) : new Date(),
        prev_month_year: new Date(),
        page_number: searchParams.get('page_number') || 0,
        limit: searchParams.get('limit') || 50,
        search_query: searchParams.get('search_query') || '',
        department_id: searchParams.get('department_id') || '',
        type: 'epf'
    }
    const [departOption, setDepartOption] = useState([])
    useReadWithGet({
        url: "department_listing",
        initialData: { limit: 50, searchQuery: '' },
        onSuccess: (res) => {
            let data = res.data.map(({ id, name }) => ({ value: id, name: name }))
            setDepartOption(data);
        }
    })
    const { list: pfList, paramsObject, setGetListParams } = useReadWithGet({
        url: 'getMemberSalaryListing',
        initialData: initialData
    })
    const { reportDownload } = useDownloadFile({
        url: 'salary_report',
        filename: 'provident-fund-report.xlsx'
    })
    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            search_query: value,
            page_number: 0
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleDate = (value) => {
        let data = {
            ...paramsObject,
            month_year: value
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleChangeDepartment = (val) => {
        let data = {
            ...paramsObject,
            department_id: val,
            page_number: 0
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleDownloadReport = () => {
        let data = {
            month_year: paramsObject.month_year,
            type: 'epf'
        }
        reportDownload.mutate(data)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_number: val.selected
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    return (
        <Salary>
            {(reportDownload.isLoading || pfList.isLoading) &&  <div className=' w-full h-full fixed top-0 left-0 flex items-center justify-center bg-[#ffffffbf] z-50'>
                <img src={spinner} alt="spinner" className='w-20 h-20' />
            </div>}
            <div className=' mt-14 mb-20'>
                <div className=''>
                    <form className='flex items-end justify-between px-5'>
                        <div className='flex items-end space-x-4'>
                            <div>
                                <input type="text" placeholder='Search By Name...' name='search_query' className='input' onChange={handleSearch} />
                            </div>
                            <div className='min-w-[10rem]  basis-[35%]'>
                                <label className=' roboto label'>Select Month/Year</label>
                                <DatePicker required name='month_year' autoComplete='false' className='input' dateFormat="MM/yyyy" showMonthYearPicker selected={paramsObject?.month_year} onChange={(value) => handleDate(value, 'month_year')} />
                            </div>
                            <div className="">
                                <label htmlFor="salary" className='label'>Select Department:</label>
                                <SelectSearch
                                    onChange={handleChangeDepartment}
                                    search
                                    options={departOption}
                                    value={paramsObject.department_id}
                                    name="department"
                                    placeholder="Select department" />
                            </div>
                        </div>
                        <div className='px-4'>
                            <div className='flex space-x-4 items-end justify-between'>
                                <div className=''>
                                    <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Download Report</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    {pfList?.data?.data?.length <= 0 
                    ? <p className=' text-center font-semibold text-lg my-5'>No Data Found</p> 
                    :  <div className="max-w-full overflow-x-auto px-4 mt-8">
                        <table className="table-auto w-full">
                            <thead>
                                <tr className=" bg-[color:var(--color1)] text-center">
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th>
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Name</th>
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Department</th>
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">PF Number</th>
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pfList?.data?.data?.map((data, idx) => {
                                    const { employee_name, pf_account_number, department_name, id,amount } = data
                                    return <tr key={idx} className='bg-gray-200 hover:bg-gray-100 cursor-pointer'>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">
                                            {(paramsObject.limit * (parseInt(paramsObject.page_number) + 1)) - (paramsObject.limit - (idx + 1))}
                                        </td>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8] capitalize">{department_name || '---'}</td>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8] capitalize">{pf_account_number || '---'}</td>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8] capitalize">{amount || '---'}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div className='px-5 mt-5'>
                            <Pagination
                                currentPage={+paramsObject.page_number}
                                lengthofItems={pfList?.data?.total_count}
                                limit={paramsObject.limit}
                                onPageChange={handlePageClick}
                            />
                        </div>
                    </div>}
                </div>
            </div>
        </Salary>
    )
}

export default ProvidentFund