import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'

const OvertimeReimbursement = forwardRef(({ label, name, data }, ref) => {
    let reimbursementMap = {
        hourly_basis: {
            hour: 1,
            rate: 1.5
        },
        comp_off: {
            fullHour: 9,
            halfHour: 4.5
        },
        pay_for_entire_day: {
            minHour: 7,
        }
    }
    const [reimbursementState, setReimbursementState] = useState({})
    useImperativeHandle(ref, () => ({
        getOverTimeReimbursement: () => {
            return reimbursementState
        }
    }))
    const handleSetReimbursementValue = (e) => {
        
        let value = e.target.value
        setReimbursementState({
            [value]: reimbursementMap[value]
        })
    }

    useEffect(() => {
        if(data){
            setReimbursementState(data)
        }
    },[data])

    const handleChangeReimbursement = (value,i,j) => {
        let copyReimbursementState = reimbursementState
        reimbursementState[i][j] = value
        setReimbursementState({...copyReimbursementState})
    }

    return (
        <div>
            <div className='flex items-center mt-4 space-x-5'>
                <div className=' space-x-2'>
                    <input type="radio" onChange={handleSetReimbursementValue} id={`Hourly_Basis${name}`} name={name} value="hourly_basis" checked={reimbursementState.hasOwnProperty('hourly_basis')}/>
                    <label htmlFor={`Hourly_Basis${name}`}>Hourly Basis</label>
                </div>
                <div className=' space-x-2'>
                    <input type="radio" onChange={handleSetReimbursementValue} id={`Comp_Off${name}`} name={name} value="comp_off" checked={reimbursementState.hasOwnProperty('comp_off')}/>
                    <label htmlFor={`Comp_Off${name}`}>Comp Off</label>
                </div>
                <div className=' space-x-2'>
                    <input type="radio" onChange={handleSetReimbursementValue} id={`Entire${name}`} name={name} value="pay_for_entire_day" checked={reimbursementState.hasOwnProperty('pay_for_entire_day')}/>
                    <label htmlFor={`Entire${name}`}>Pay For Entire Day</label>
                </div>
            </div>
            <div className='mt-8'>
                {reimbursementState?.hourly_basis &&
                    <div className='space-x-2'>
                        <label htmlhtmlFor="">Hourly Rates: </label>
                        <input type="text" className='input w-12 text-center' onChange={(e) => handleChangeReimbursement(e.target.value,'hourly_basis','hour')} value={reimbursementState.hourly_basis.hour} />
                        <span>hour = </span>
                        <input type="text" className='input w-16 text-center' onChange={(e) => handleChangeReimbursement(e.target.value,'hourly_basis','rate')} value={reimbursementState.hourly_basis.rate} />
                        <span>hours pay </span>
                    </div>}


                {reimbursementState?.comp_off &&
                    <div className='space-x-24 flex px-3'>
                        <div className='space-x-3'>
                            <label htmlhtmlFor="">Full Day </label>
                            <input type="text" className='input w-16 text-center' onChange={(e) => handleChangeReimbursement(e.target.value,'comp_off','fullHour')} value={reimbursementState.comp_off.fullHour} />
                            <label htmlhtmlFor="">Hours</label>
                        </div>
                        <div className='space-x-3'>
                            <label htmlhtmlFor="">Half Day </label>
                            <input type="text" className='input w-16 text-center' onChange={(e) => handleChangeReimbursement(e.target.value,'comp_off','halfHour')} value={reimbursementState.comp_off.halfHour} />
                            <label htmlhtmlFor="">Hours</label>
                        </div>
                    </div>}


                {reimbursementState?.pay_for_entire_day &&
                    <div className='space-x-24 flex px-3'>
                        <div className='space-x-3'>
                            <label htmlhtmlFor="">Minimum Hours </label> 
                            <input type="text" className='input w-16 text-center' onChange={(e) => handleChangeReimbursement(e.target.value,'pay_for_entire_day','minHour')} value={reimbursementState.pay_for_entire_day.minHour} />
                        </div>
                    </div>}
            </div>
        </div>
    )
})

export default OvertimeReimbursement