import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import useRead from "../../hooks/useRead";
import usePut from "../../hooks/usePut";
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  PencilAltIcon,
  XIcon,
} from "@heroicons/react/solid";
import Calendar from "../../components/Dates/Calendar";
import { getAttendanceData } from "../../api/leaveApplicationForm";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Alert, Modal, Snackbar } from "@mui/material";
import spinner from "../../assets/images/spinner.gif";
import { useQueryClient } from "@tanstack/react-query";
import { numberWithComma } from "../../utility";
import Loader from "../../components/Loader";
import useDownloadFile from "../../hooks/useDownloadFile";
import { config } from "../../config";

const tempData = {
  employee_name: "",
  salary: "",
  gst: "",
  professional_tax: "",
  approved_leaves: "",
  tds: "",
  base_salary: "",
  total_days_present: "",
  total_days_absent: "",
  department: "",
  designation: "",
  absent_deduction: "",
  total_pending_dates: "",
  days_present: "",
  days_absent: "",
  public_holidays: "",
  approved_leaves_dates: "",
  month_days: "",
  pending_dates: "",
  prev_month_sal_deduction: "",
  previous_month_absent: "",
  previous_month_days: "",
};

const getDateMonthString = (date) => {
  let tempDate = new Date(date);
  let month = tempDate.getMonth() + 1;
  return tempDate.getFullYear() + "-" + month;
};

const SalaryLogs = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let employee = searchParams.get("emp") === "Employee`" ? true : false;
  const [currentDatesSection, setCurrentDatesSection] = useState("");
  const [listData, setListData] = useState(tempData);
  const [initialListData, setInitialListData] = useState(null);
  const [filterData, setFilterData] = useState({
    month_and_year: new Date(searchParams.get("date")),
    prev_month_and_year: new Date(searchParams.get("prev_date")),
  });
  // popup messages
  const [createSuccessMessage, setCreateSuccessMessage] = useState(false);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState(false);
  const [errorWhileGettingData, setErrorWhileGettingError] = useState(false);
  const { list } = useRead({
    url: "getUserPayout",
    initialData: {
      month_year: filterData.month_and_year,
      user_id: searchParams.get("id"),
    },
    onSuccess: (data) => {
      setInitialListData(data?.data[0]);
      setListData(data?.data);
    },
    onError: () => {
      setErrorWhileGettingError(true);
    },
  });

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setListData({
      ...listData,
      [name]: value,
    });
  };

  const [showDates, setShowDates] = useState(false);

  const handleViewDates = (datesSection) => {
    getCalendar.handleAuth.mutate({
      user_id: searchParams.get("id"),
      month_year: getDateMonthString(searchParams.get("date")),
    });
    setShowDates(true);
    setCurrentDatesSection(datesSection);
  };

  const getCalendar = usePut({
    url: "getCalenderDetails",
    onSuccess: (data) => {
      let resData = data?.data?.data[0];
      setListData({
        ...listData,
        ...resData,
      });
    },
    onError: () => {},
    onSettled: () => {},
  });

  const handleSetTDS = (e) => {
    let copyObject = listData;
    copyObject["salary_payout"]["tds"] = +e.target.value;
    setListData({ ...listData });
  };

  const storeTdsInPayout = usePut({
    url: "storeTdsInPayout",
    onSuccess: (data) => {
      setUpdateSuccessMessage(true);
    },
  });

  const handleStoreTdsInPayout = () => {
    let data = {
      payout_id: listData.salary_payout.payout_id,
      tds: listData.salary_payout.tds,
    };
    storeTdsInPayout.handleAuth.mutate(data);
  };

  const { reportDownload } = useDownloadFile({
    url: "salary_slip",
    filename: "salary_slip.pdf",
  });

  const handleDownloadSalarySlip = () => {
    let data = {
      month_year: filterData.month_and_year,
      user_id: searchParams.get("id"),
    };
    reportDownload.mutate(data);
  };

  return (
    <Layout>
      <div className="mx-6 my-4 flex justify-between items-center">
        <button
          onClick={() => navigate(-1, { replace: true })}
          className="flex items-center hover:underline"
        >
          <ChevronLeftIcon className="w-6 h-6" />
          <span className="font-semibold">Back</span>
        </button>
        {/* <button onClick={handleDownloadSalarySlip} className=' text-blue-500 font-semibold hover:underline'>Download Salary Slip</button> */}
        <a
          // target='blank'
          download
          href={`${config.apiurl}salary_slip?month_year=${
            filterData.month_and_year
          }&user_id=${searchParams.get("id")}`}
          className=" text-blue-500 font-semibold hover:underline"
        >
          Download Salary Slip
        </a>
      </div>
      {list?.isLoading || reportDownload.isLoading ? (
        <div className=" w-16 h-16 mx-auto mt-8">
          <img src={spinner} alt="spinner" className="w-full h-full" />
        </div>
      ) : (
        <div className="mx-6 mb-10">
          <div>
            {listData?.salary_payout?.processing_status === "1" ? (
              <snap className=" px-4 py-2 bg-green-100 text-green-600 rounded-md font-semibold">
                Payroll Processed
              </snap>
            ) : listData?.salary_payout?.processing_status === "2" ? (
              <snap className=" px-4 py-2 bg-yellow-100 text-yellow-600 rounded-md font-semibold">
                Payroll Under Process
              </snap>
            ) : listData?.salary_payout?.processing_status === "3" ? (
              <snap className=" px-4 py-2 bg-yellow-100 text-yellow-600 rounded-md font-semibold">
                Payroll Pending
              </snap>
            ) : listData?.salary_payout?.processing_status === "4" ? (
              <snap className=" px-4 py-2 bg-yellow-100 text-yellow-600 rounded-md font-semibold">
                Draft in process
              </snap>
            ) : listData?.salary_payout?.processing_status === "5" ? (
              <snap className=" px-4 py-2 bg-yellow-100 text-yellow-600 rounded-md font-semibold">
                Viewing In Draft Mode
              </snap>
            ) : (
              <snap className=" px-4 py-2 bg-blue-100 text-blue-600 rounded-md font-semibold">
                Viewing In Draft Mode
              </snap>
            )}
          </div>
          <div className="document-body py-4  overflow-auto">
            <div className="lg:flex flex-row mt-8 gap-x-8 space-y-8 lg:space-y-0">
              <table className="lg:min-w-[35rem] lg:w-[40%] w-full">
                <thead>
                  <tr>
                    <th
                      colspan="2"
                      className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white"
                    >
                      Employee Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Employee Name
                    </td>
                    <td className="border py-3 px-4">
                      {listData?.employee_details?.employee_name || "----"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">Designation</td>
                    <td className="border py-3 px-4">
                      {listData?.employee_details?.designation || "----"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">Group</td>
                    <td className="border py-3 px-4">
                      {listData?.employee_details?.department || "----"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">Code</td>
                    <td className="border py-3 px-4">
                      {listData?.employee_details?.code || "----"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">PF No.</td>
                    <td className="border py-3 px-4">
                      {listData?.employee_details?.pf_account_number || "----"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="lg:min-w-[35rem] lg:w-[40%] w-full">
                <thead>
                  <tr>
                    <th
                      colspan="2"
                      className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white"
                    >
                      Employee Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold border py-3 px-4">ESI No.</td>
                    <td className="border py-3 px-4">
                      {listData?.employee_details?.esi_insurance_number ||
                        "----"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">Paid Days</td>
                    <td className="border py-3 px-4">
                      {listData?.salary_payout?.total_paid_days || "----"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">Unpaid Days</td>
                    <td className="border py-3 px-4">
                      {listData?.salary_payout?.total_absent_days || "----"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Opening Leave Balance
                    </td>
                    <td className="border py-3 px-4">
                      {listData?.leave_balance_data?.opening_leave_balance}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Closing Leave Balance
                    </td>
                    <td className="border py-3 px-4">
                      {listData?.leave_balance_data?.closing_leave_balance}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">Gross Salary</td>
                    <td className="border py-3 px-4">
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.gross_salary)
                      ) || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table className="mt-8 w-full">
              <thead>
                <tr>
                  <th className="font-bold py-3 px-4 bg-[color:var(--color1)] text-white "></th>
                  <th
                    colspan="4"
                    className="font-bold py-3 px-4 bg-[color:var(--color1)] text-white"
                  >
                    Attendance Details
                  </th>
                  <th className="font-bold  py-3 px-4 bg-[color:var(--color1)] text-white">
                    <button
                      className="text-center bg-gray-200 py-1 px-2 rounded cursor-pointer text-gray-700 roboto font-semibold text-sm hover:bg-gray-300 ml-6"
                      onClick={() => handleViewDates("present")}
                    >
                      Curr. Month
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-bold border py-3 px-4 text-center">
                    Worked Days
                  </td>
                  <td className="font-bold border py-3 px-4 text-center">
                    Late Days
                  </td>
                  <td className="font-bold border py-3 px-4 text-center">
                    Penalty Days
                  </td>
                  <td className="font-bold border py-3 px-4 text-center">
                    Paid Leaves
                  </td>
                  <td className="font-bold border py-3 px-4 text-center">
                    Absent Days
                  </td>
                  <td className="font-bold border py-3 px-4 text-center">
                    Holidays
                  </td>
                </tr>
                <tr>
                  <td className="border py-3 px-4 text-center">
                    {listData.salary_payout?.total_working_days}
                  </td>
                  <td className="border py-3 px-4 text-center">
                    {listData.salary_payout?.total_late_days}
                  </td>
                  <td className="border py-3 px-4 text-center">
                    {listData.salary_payout?.late_penalty_days}
                  </td>
                  <td className="border py-3 px-4 text-center">
                    {listData.salary_payout?.total_leave_days}
                  </td>
                  <td className="border py-3 px-4 text-center">
                    {listData.salary_payout?.total_absent_days}
                  </td>
                  <td className="border py-3 px-4 text-center">
                    {listData.salary_payout?.total_holidays}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="mt-8 w-full">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    className="font-bold py-3 px-4 bg-[color:var(--color1)] text-white"
                  >
                    Basic Leaves Detail
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-bold border py-3 px-4 text-center">
                    In month
                  </td>
                  <td className="font-bold border py-3 px-4 text-center">
                    Utilized
                  </td>
                  <td className="font-bold border py-3 px-4 text-center">
                    Opening Balance
                  </td>
                  <td className="font-bold border py-3 px-4 text-center">
                    Closing Balance
                  </td>
                </tr>
                <tr>
                  <td className="border py-3 px-4 text-center">
                    {listData.leave_balance_data?.basic_accured_in_month}
                  </td>
                  <td className="border py-3 px-4 text-center">
                    {listData.leave_balance_data?.basic_accured_utilised}
                  </td>
                  <td className="border py-3 px-4 text-center">
                    {listData.leave_balance_data?.basic_accured_opening_balance}
                  </td>
                  <td className="border py-3 px-4 text-center">
                    {listData.leave_balance_data?.basic_accured_closing_balance}
                  </td>
                </tr>
              </tbody>
            </table>
            {listData.leave_balance_data?.ot_leave_applicable && (
              <>
                <table className="mt-8 w-full">
                  <thead>
                    <tr>
                      <th
                        colspan="4"
                        className="font-bold py-3 px-4 bg-[color:var(--color1)] text-white"
                      >
                        Holiday OT Detail
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-bold border py-3 px-4 text-center">
                        In month
                      </td>
                      <td className="font-bold border py-3 px-4 text-center">
                        Utilized
                      </td>
                      <td className="font-bold border py-3 px-4 text-center">
                        Opening Balance
                      </td>
                      <td className="font-bold border py-3 px-4 text-center">
                        Closing Balance
                      </td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-4 text-center">
                        {listData.leave_balance_data?.holiday_ot_in_month}
                      </td>
                      <td className="border py-3 px-4 text-center">
                        {listData.leave_balance_data?.holiday_ot_utilised}
                      </td>
                      <td className="border py-3 px-4 text-center">
                        {
                          listData.leave_balance_data
                            ?.holiday_ot_opening_balance
                        }
                      </td>
                      <td className="border py-3 px-4 text-center">
                        {
                          listData.leave_balance_data
                            ?.holiday_ot_closing_balance
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="mt-8 w-full">
                  <thead>
                    <tr>
                      <th
                        colspan="4"
                        className="font-bold py-3 px-4 bg-[color:var(--color1)] text-white"
                      >
                        Normal OT Detail
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-bold border py-3 px-4 text-center">
                        In month
                      </td>
                      <td className="font-bold border py-3 px-4 text-center">
                        Utilized
                      </td>
                      <td className="font-bold border py-3 px-4 text-center">
                        Opening Balance
                      </td>
                      <td className="font-bold border py-3 px-4 text-center">
                        Closing Balance
                      </td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-4 text-center">
                        {listData.leave_balance_data?.normal_ot_in_month}
                      </td>
                      <td className="border py-3 px-4 text-center">
                        {listData.leave_balance_data?.normal_ot_utilised}
                      </td>
                      <td className="border py-3 px-4 text-center">
                        {listData.leave_balance_data?.normal_ot_opening_balance}
                      </td>
                      <td className="border py-3 px-4 text-center">
                        {listData.leave_balance_data?.normal_ot_closing_balance}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            <div className="flex flex-row mt-8">
              <table className="flex-1 mr-4">
                <thead>
                  <tr>
                    <th className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white">
                      Earnings
                    </th>
                    <th className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold border py-3 px-4">Basic</td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.basic)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">OA</td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.other_allowed)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">HRA</td>
                    {/* <td className='border py-3 px-4 flex '>₹ <input type="number" name="bonus" onChange={handleChange} className='w-full focus:outline-none ml-1' disabled={!showEditable} defaultValue={Math.round(listData?.salary_payout?.hra)} /></td> */}
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.hra)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">CONV</td>
                    {/* <td className='border py-3 px-4 flex '>₹ <input type="number" name="commission" onChange={handleChange} className='w-full focus:outline-none ml-1' disabled={!showEditable} defaultValue={Math.round(listData?.salary_payout?.conveyance)} /></td> */}
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.conveyance)
                      ) || 0}
                    </td>
                  </tr>
                  {listData?.salary_payout?.variable_ot ? (
                    <tr>
                      <td className="font-bold border py-3 px-4">O</td>
                      {/* <td className='border py-3 px-4 flex '>₹ <input type="number" name="commission" onChange={handleChange} className='w-full focus:outline-none ml-1' disabled={!showEditable} defaultValue={Math.round(listData?.salary_payout?.variable_ot)} /></td> */}
                      <td className="border py-3 px-4">
                        ₹{" "}
                        {numberWithComma(
                          Math.round(listData?.salary_payout?.variable_ot)
                        ) || 0}
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td className="font-bold border-t-2 border border-t-black py-3 px-4">
                      Gross
                    </td>
                    <td className="border border-t-black border-t-2 py-3 px-4 font-bold">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.gross_salary)
                      ) || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="flex-1 ml-4">
                <thead>
                  <tr>
                    <th className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white">
                      Deduction
                    </th>
                    <th className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Professional Tax (P Tax)
                    </td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.professional_tax)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Provident Fund (PF)
                    </td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.epf)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Employees' State Insurance (ESI){" "}
                    </td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.esic)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Employees' (MLWF){" "}
                    </td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.employee_mlwf)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4 border-t-black border-t-2">
                      Total
                    </td>
                    <td className="font-bold border py-3 px-4 border-t-black border-t-2">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(
                          +listData?.salary_payout?.professional_tax +
                            +listData?.salary_payout?.epf +
                            +listData?.salary_payout?.esic
                        )
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex flex-row mt-8">
              <table className="flex-1 mr-4">
                <thead>
                  <tr>
                    <th className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white">
                      Contributions
                    </th>
                    <th className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Provident Fund (PF)
                    </td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.employer_epf)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Employer's State Insurance (ESI)
                    </td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.employer_esic)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Employer's (MLWF)
                    </td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.employer_mlwf)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border-t-2 border border-t-black py-3 px-4">
                      Total
                    </td>
                    <td className="border border-t-black border-t-2 py-3 px-4 font-bold">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(
                          +listData?.salary_payout?.employer_epf +
                            +listData?.salary_payout?.employer_esic
                        )
                      ) || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="flex-1 ml-4">
                <thead>
                  <tr>
                    <th className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white">
                      Adjustments
                    </th>
                    <th className="font-bold border py-3 px-4 bg-[color:var(--color1)] text-white">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold border py-3 px-4">TDS</td>
                    <td className="border py-3 px-4">
                      <div className="flex items-center space-x-2">
                        <span>₹</span>
                        <input
                          type="text"
                          disabled={
                            listData?.salary_payout?.processing_status ===
                              "1" ||
                            listData?.salary_payout?.processing_status === "2"
                          }
                          className="input"
                          onChange={handleSetTDS}
                          value={listData?.salary_payout?.tds || 0}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">Adv.</td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.advance)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4">
                      Late Deduction
                    </td>
                    <td className="border py-3 px-4">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(listData?.salary_payout?.late_deduction)
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border py-3 px-4 border-t-black border-t-2">
                      Total
                    </td>
                    <td className="font-bold border py-3 px-4 border-t-black border-t-2">
                      ₹{" "}
                      {numberWithComma(
                        Math.round(
                          +listData?.salary_payout?.tds +
                            +listData?.salary_payout?.advance +
                            +listData?.salary_payout?.late_deduction
                        )
                      ) || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex items-center space-x-16">
            <p className="my-3 mb-6 font-bold">
              OT Hours: {listData?.salary_payout?.total_ot_hours || "----"}
            </p>
            <p className="my-3 mb-6 font-bold">
              Total Holiday OT Hours:{" "}
              {listData?.salary_payout?.total_holiday_ot_hours || "----"}
            </p>
            <p className="my-3 mb-6 font-bold">
              Total Late Hours:{" "}
              {listData?.salary_payout?.late_duration || "----"}
            </p>
          </div>
          <div className="flex items-center space-x-16">
            <p className="my-3 mb-6 font-bold">
              Total Variable OT Hours:{" "}
              {listData?.salary_payout?.total_variable_ot_hours || "----"}
            </p>
            <p className="my-3 mb-6 font-bold">
              Variable OT Hours:{" "}
              {listData?.salary_payout?.variable_ot_hours || "----"}
            </p>
            <p className="my-3 mb-6 font-bold">
              Holiday Variable OT Hours:{" "}
              {listData?.salary_payout?.holiday_variable_ot_hours || "----"}
            </p>
          </div>
          <div className="flex items-center space-x-16">
            <p className="my-3 mb-6 font-bold">
              Net Salary Payable: ₹{" "}
              {numberWithComma(
                Math.round(listData?.salary_payout?.net_salary)
              ) || 0}
            </p>
            <p className="my-3 mb-6 font-bold">
              Net OT: ₹{" "}
              {numberWithComma(Math.round(listData?.salary_payout?.net_ot)) ||
                0}
            </p>
          </div>
          <div className="flex items-center space-x-16">
            <p className="my-3 mb-6 font-bold">
              Net Payable: ₹{" "}
              {numberWithComma(
                Math.round(listData?.salary_payout?.net_payable)
              ) || 0}
            </p>
          </div>
          <div>
            {listData?.salary_payout?.processing_status === "1" ? (
              <snap className=" px-4 py-2 bg-green-100 text-green-600 rounded-md font-semibold">
                Payroll Processed
              </snap>
            ) : listData?.salary_payout?.processing_status === "2" ? (
              <snap className=" px-4 py-2 bg-yellow-100 text-yellow-600 rounded-md font-semibold">
                Payroll Under Process
              </snap>
            ) : listData?.salary_payout?.processing_status === "3" ? (
              <snap className=" px-4 py-2 bg-yellow-100 text-yellow-600 rounded-md font-semibold">
                Payroll Pending
              </snap>
            ) : listData?.salary_payout?.processing_status === "4" ? (
              <snap className=" px-4 py-2 bg-yellow-100 text-yellow-600 rounded-md font-semibold">
                Draft in process
              </snap>
            ) : listData?.salary_payout?.processing_status === "5" ? (
              <button
                onClick={handleStoreTdsInPayout}
                className="px-6 text-white rounded-md bg-[color:var(--color1)] py-2"
              >
                <div className=" flex items-center space-x-3">
                  <span>Save TDS</span>
                  {storeTdsInPayout.handleAuth.isLoading && <Loader />}
                </div>
              </button>
            ) : (
              <button
                onClick={handleStoreTdsInPayout}
                className="px-6 text-white rounded-md bg-[color:var(--color1)] py-2"
              >
                <div className=" flex items-center space-x-3">
                  <span>Save TDS</span>
                  {storeTdsInPayout.handleAuth.isLoading && <Loader />}
                </div>
              </button>
            )}
          </div>
        </div>
      )}
      {
        <Modal
          open={showDates}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
        >
          <div className="document-wrapper px-6 divide-y bg-white h-max max-h-[80%] w-[40rem] ml-[30%] mt-[5%] overflow-auto">
            {getCalendar.handleAuth.isLoading ? (
              <div className=" w-16 h-16 mx-auto mt-8">
                <img src={spinner} alt="spinner" className="w-full h-full" />
              </div>
            ) : (
              <>
                <div className="document-head py-4">
                  <div className="flex items-center">
                    <div
                      className=" w-5 h-5 cursor-pointer mr-3"
                      onClick={() => setShowDates(false)}
                    >
                      <ArrowLeftIcon className=" text-black w-full h-full" />
                    </div>
                    <h3 className=" text-gray-700 text-2xl font-semibold">
                      Calender
                    </h3>
                  </div>
                </div>
                <div className=" pt-2 pb-4 overflow-auto">
                  {currentDatesSection === "present" && (
                    <>
                      <div className="flex flex-row flex-wrap mb-4 justify-between">
                        <div className="flex mt-4 flex-row mr-6 items-center">
                          <p className="px-2 py-2 rounded flex items-center justify-center bg-[color:var(--color1)] text-white text-center uppercase font-semibold text-sm"></p>
                          <p className="ml-2 text-sm">Present</p>
                        </div>
                        <div className="flex mt-4 flex-row mr-6 items-center">
                          <p className="px-2 py-2 bg-[color:var(--color13)] rounded flex items-center justify-center text-white text-center uppercase font-semibold text-sm"></p>
                          <p className="ml-2 text-sm">Absent</p>
                        </div>
                        <div className="flex mt-4 flex-row mr-6 items-center">
                          <p className="px-2 py-2 bg-[color:var(--color8)] rounded flex items-center justify-center text-white text-center uppercase font-semibold text-sm"></p>
                          <p className="ml-2 text-sm">Public Holidays</p>
                        </div>
                        <div className="flex mt-4 flex-row mr-6 items-center">
                          <p className="px-2 py-2 bg-[color:var(--color14)] rounded flex items-center justify-center text-white text-center uppercase font-semibold text-sm"></p>
                          <p className="ml-2 text-sm">Data pending</p>
                        </div>
                        <div className="flex mt-4 flex-row mr-6 items-center">
                          <p className="px-2 py-2 bg-indigo-600 rounded flex items-center justify-center bg-[color:var(--color1)] text-white text-center uppercase font-semibold text-sm"></p>
                          <p className="ml-2 text-sm">Approved Leaves</p>
                        </div>
                      </div>
                      <Calendar
                        attendanceDates={{
                          days_present: listData?.salary_payout?.present_dates,
                          days_absent: listData?.salary_payout?.absent_dates,
                          approved_leaves_dates:
                            listData?.salary_payout?.leave_dates,
                          pending_dates: listData?.salary_payout?.pending_dates,
                          month_days: listData?.salary_payout?.month_dates,
                          public_holidays:
                            listData?.salary_payout?.holiday_dates,
                        }}
                        monthDateObject={filterData?.month_and_year}
                      />
                    </>
                  )}
                  {currentDatesSection === "previous" && (
                    <>
                      {/* <div className='grid grid-cols-1 gap-2 pb-8'>
                                                    <p className='px-4 py-2 rounded flex items-center justify-center bg-[color:var(--color13)] text-white text-center uppercase font-semibold text-sm'>Prev Month Deduction For Current Month</p>
                                                </div>
                                                <Calendar attendanceDates={{
                                                    days_present: Array(),
                                                    days_absent: listData['previous_month_absent'],
                                                    approved_leaves_dates: Array(),
                                                    pending_dates: Array(),
                                                    month_days: listData['previous_month_days'],
                                                    public_holidays: Array()
                                                }}
                                                    monthDateObject={filterData?.prev_month_and_year} /> */}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </Modal>
      }
      <Snackbar
        open={createSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setCreateSuccessMessage(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Payment Created Successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={updateSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setUpdateSuccessMessage(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Payment Updated Successfully
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorWhileGettingData}
        autoHideDuration={6000}
        onClose={() => setErrorWhileGettingError(false)}
      >
        <Alert
          onClose={() => setErrorWhileGettingError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          There was an error while retrieving the data; please try again later.
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default SalaryLogs;
