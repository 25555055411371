import { Modal } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import DatePicker from "react-datepicker";
import Pagination from "../../components/pagination";
import BulkSalaryModal from "../../components/salary/BulkSalaryModal";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { config } from "../../config";
import spinner from "../../assets/images/spinner.gif";
import { getForm16List } from "../../api/employeeDetails";

const getDateRangeString = (date) => {
  const tempDate = new Date(date);
  const startYear = tempDate.getFullYear();
  const endYear = startYear + 1;
  return `${startYear}-${endYear}`;
};

function Form16() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let initialData = {
    financial_year: searchParams.get("financial_year")
      ? new Date(searchParams.get("financial_year"))
      : new Date(),
    prev_month_and_year: new Date(),
    page_number: searchParams.get("page_number") || 0,
    limit: searchParams.get("limit") || 20,
    search_query: searchParams.get("search_query") || "",
  };

  const [filterData, setFilterData] = useState(initialData);
  const [isFetching, setIsFetching] = useState(false);
  const [form16List, setForm16List] = useState([]);
  const [count, setCount] = useState("");
  const [companyPayout, setCompanyPayout] = useState([]);

  // useCallback to memoize the function and avoid unnecessary re-renders
  const handleDate = useCallback(
    (value, name) => {
      let data = {
        ...filterData,
        [name]: value,
      };
      setFilterData(data);
      setSearchParams(data);
    },
    [filterData, setSearchParams]
  );

  const handleFetchForm16List = useCallback((data) => {
    const temp = {
      ...data,
      financial_year: getDateRangeString(data.financial_year),
      page_number: data.page_number,
      search_query: data.search_query,
      limit: data.limit,
    };
    console.log(data.financial_year);
    setIsFetching(true);
    getForm16List(temp)
      .then((res) => {
        setForm16List(res?.data?.data);
        setCompanyPayout(res?.data?.company_payout);
        setCount(res?.data?.total_count);
        setIsFetching(false);
        scrollToTop();
      })
      .catch((err) => {
        alert("Something went wrong!");
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    handleFetchForm16List(filterData);
  }, [filterData, handleFetchForm16List]);

  const handleNavigateToEmployeeDetail = (id) => {
    navigate(`/employee-form16-details/${id}`);
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    let data = {
      ...filterData,
      [name]: value,
    };
    setFilterData(data);
    setSearchParams(data);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePageClick = (val) => {
    let data = {
      ...filterData,
      page_number: val.selected,
    };
    setFilterData(data);
    setSearchParams(data);
  };

  const [isImportModalOpen, setIsModalOpen] = useState(false);

  // Custom function to format year options in yyyy-yyyy format
  const getYearRange = (startYear) => {
    const endYear = startYear + 1;
    return `${startYear}-${endYear}`;
  };
  const handleGenerate = async (id) => {
    try {
      // Navigate to Form16Input page and pass necessary parameters
      navigate("/form16input", {
        state: {
          id: id,
          financial_year: filterData.financial_year,
          prev_month_and_year: filterData.prev_month_and_year,
        },
      });
    } catch (error) {
      console.error("Error generating form:", error);
      alert("Failed to generate form. Please try again.");
    }
  };

  return (
    <Layout>
      {isFetching && (
        <div className="w-full h-full fixed top-0 left-0 flex items-center justify-center bg-[#ffffffbf] z-50">
          <img src={spinner} alt="spinner" className="w-20 h-20" />
        </div>
      )}
      <div className="mt-14 mb-20">
        <div>
          <form className="flex items-end justify-between px-5">
            <div className="flex items-end space-x-4">
              <div>
                <input
                  type="search"
                  placeholder="Search By Name..."
                  value={filterData?.search_query}
                  name="search_query"
                  className="input"
                  onChange={handleSearch}
                />
              </div>
              <div className="min-w-[10rem] basis-[35%]">
                <label className="roboto label">Select Year</label>
                <DatePicker
                  required
                  name="financial_year"
                  autoComplete="off"
                  className="input"
                  dateFormat="yyyy"
                  showYearPicker
                  selected={
                    filterData?.financial_year instanceof Date
                      ? filterData?.financial_year
                      : new Date()
                  } // Provide a default value if filterData?.financial_year is invalid
                  onChange={(value) => handleDate(value, "financial_year")}
                />
              </div>
            </div>
          </form>
        </div>
        {form16List?.length > 0 ? (
          <>
            <div className="max-w-full overflow-x-auto px-4 mt-8">
              <table className="table-auto w-full">
                <thead>
                  <tr className="bg-[color:var(--color1)] text-center">
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                      Sr.No
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Name
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      User Type
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {form16List?.map((data, idx) => {
                    const { employee_name, status, user_type, id } = data;
                    return (
                      <tr
                        key={idx}
                        className="bg-gray-200 hover:bg-gray-100 cursor-pointer"
                        // onClick={() => handleNavigateToEmployeeDetail(id)}
                      >
                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">
                          {filterData.limit *
                            (parseInt(filterData.page_number) + 1) -
                            (filterData.limit - (idx + 1))}
                        </td>
                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">
                          {employee_name}
                        </td>
                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8] capitalize">
                          {user_type}
                        </td>
                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                          {/* {status === "left" ? (
                            <div className="flex items-center justify-center">
                              Left
                            </div>
                          ) : status === "joining_date_diff" ? (
                            <div className="flex items-center justify-center">
                              Joining Date Diff
                            </div>
                          ) : status === "not_generated" ? ( */}
                          <Link
                            onClick={(e) => e.stopPropagation()}
                            to={{
                              pathname: `/form16/logs?id=${id}&year=${filterData?.financial_year}`,
                              state: {
                                id: id,
                                financial_year: filterData.financial_year,
                                prev_month_and_year:
                                filterData.prev_month_and_year,
                              },
                              
                            }}
                            
                            className="px-4 py-2 rounded bg-[color:var(--color1)] text-white"
                            >
                            Generate
                          </Link>

                          {/* ) : status === "generated" ? (
                            <Link
                              onClick={(e) => e.stopPropagation()}
                              to={
                                "/salary/logs?id=" +
                                id +
                                "&date=" +
                                filterData["financial_year"] +
                                "&prev_date=" +
                                filterData["prev_month_and_year"] +
                                "&status=generated" +
                                "&emp=" +
                                user_type
                              }
                              className="px-4 py-2 rounded bg-[color:var(--color1)] text-white"
                            >
                              View
                            </Link>
                          ) : null} */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="px-5 mt-5">
              <Pagination
                currentPage={+filterData.page_number}
                lengthofItems={count}
                limit={filterData.limit}
                onPageChange={handlePageClick}
              />
            </div>
          </>
        ) : (
          <p className="text-center font-semibold text-lg mt-10">
            No Employee Found
          </p>
        )}
      </div>
      <BulkSalaryModal
        open={isImportModalOpen}
        handleClose={() => setIsModalOpen(false)}
      />
    </Layout>
  );
}

export default Form16;
