import Modal from '@mui/material/Modal';
import useReadWithGet from '../../hooks/read/useReadWithGetMethod';
import { useState } from 'react';
import Loader from '../../components/Loader';
import toast from 'react-hot-toast';
import { getAttendanceReportList, getFetchFile } from '../../api/employeeDetails';


// Helper function to format date into DD/MM/YYYY
const formatDateToDDMMYYYY = (dateString) => {
  const dateObj = new Date(dateString);
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = dateObj.getFullYear();
  return `${day}-${month}-${year}`;
};

const FetchFile = ({ open, handleClose,data }) => {
  let initialDepartmentState = {
    limit: 50,
    searchQuery: '',
  };
  
  let reportState = {
    date: '',
  };
  
  const [report, setReportState] = useState(reportState);
  const [isLoading, setIsLoading] = useState(false); // Track loading state

  const handleChangeReportState = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setReportState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const [departOption, setDepartOption] = useState([]);
  
  useReadWithGet({
    url: "fetchattendancedatalisting",
    initialData: data,
    onSuccess: (res) => {
      let data = res.data.map(({ id, name }) => ({ value: id, name: name }));
      setDepartOption(data);
    },
  });

  // Handle form submission and API call
  const handleDownloadReport = async (e) => {
    e.preventDefault();
    
    // Format the date before passing it to the API
    const formattedDate = formatDateToDDMMYYYY(report.date);

    const updatedReport = {
      ...report,
      date: formattedDate, // Format the date to DD/MM/YYYY
    };

    try {
      setIsLoading(true); // Start loading
      const response = await getFetchFile(updatedReport); // Call the API function
      toast.success("File Fetched successfully!"); // Notify user on success
  
      // Call fetchattendancedatalisting API after the file is fetched
      window.location.reload();
      console.log("API Response: ", response); // Log the API response for debugging
    } catch (error) {
      toast.error("There was an error hitting the API"); // Notify user on error
      console.error("API Error: ", error);
    } finally {
      setIsLoading(false); // Stop loading
      handleClose()
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div className=' bg-white w-[40%] rounded-md absolute left-1/2 -translate-x-1/2 top-6'>
        <div>
          <h1 className=' font-semibold text-xl px-4 py-3'>File</h1>
          <form className='px-4 py-4 space-y-4' onSubmit={handleDownloadReport}>
            <div className="grid grid-cols-2 gap-4">
              <div className="">
                <label htmlFor="total_leave" className='label'>Date:<span className='mandatory'>*</span></label>
                <input
                  type="date"
                  required
                  name='date'
                  className='input disabled:cursor-not-allowed'
                  onChange={handleChangeReportState}
                  value={report.date}
                />
              </div>
            </div>
            <div className=' space-x-4 text-center'>
              <button
                disabled={isLoading} // Disable button while loading
                className=' bg-blue-200 text-blue-700 font-semibold px-4 py-2 rounded'
              >
                <div className=' flex items-center justify-center space-x-3'>
                  <span>Submit</span>
                  {isLoading && <Loader className={'text-blue-700'} />} {/* Show loader while loading */}
                </div>
              </button>
              <button type='button' onClick={handleClose} className=' border font-semibold px-4 py-2 rounded'>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default FetchFile;
