import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import axios from "axios";
import { config, TOKEN } from "../../config";
import Cookies from "universal-cookie";
import { set } from "react-hook-form";
const cookies = new Cookies();

const dummyBonusUserList = {
  data: Array.from({ length: 10 }, (v, i) => ({
    user_id: i + 1, // Unique ID for each user
    employee_name: `Employee ${i + 1}`, // Employee name
    no_of_days: Math.floor(Math.random() * 30) + 1, // Random number of days (1-30)
    bonus_amount: (Math.random() * 500).toFixed(2), // Random bonus amount
    status: Math.random() > 0.5 ? "Active" : "Inactive", // Random status
    base_salary: (Math.random() * 3000).toFixed(2), // Random base salary
    gross_salary: (Math.random() * 4000).toFixed(2), // Random gross salary
  })),
};

const BonusForm = forwardRef(({ getValues, setIsFetchingEmployee }, ref) => {
  const [selectedUser, setSelectedUser] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [bonusUserList, setBonusUserList] = useState();
  const [totalDays, setTotalDays] = useState();

  useImperativeHandle(ref, () => ({
    fetchBonusUserListing: fetchBonusUserListing,
    getSelectedUser: () => selectedUser,
    getTotalDays: () => totalDays,
  }));

  const fetchBonusUserListing = async () => {
    setIsFetchingEmployee(true);
    try {
      let token = cookies.get(TOKEN);
      let from_date_formatted = new Date(getValues("from_date")).toISOString();
      let to_date_formatted = new Date(getValues("to_date")).toISOString();

      const formData = new FormData();
      formData.append("from_date", from_date_formatted); // ISO 8601 Z format
      formData.append("to_date", to_date_formatted); // ISO 8601 Z format

      const response = await axios({
        method: "POST",
        url: `${config.apiurl}bonus_user_listing`,
        headers: { Authorization: token ? `Bearer ${token}` : "" },
        data: formData,
      });
      setIsFetchingEmployee(false);
      setBonusUserList(response.data);
      setTotalDays(response?.data?.total_no_of_days)
      // setBonusUserList(dummyBonusUserList);
    } catch (err) {
      setIsFetchingEmployee(false);
      console.error("API call failed:", err);
    }
  };

  {
    /* For NON-OBJECT */
  }

  // const handleSelectChange = (userId) => {
  //   if (selectedUser.includes(userId)) {
  //     // Deselect user
  //     setSelectedUser((prevSelectedUser) =>
  //       prevSelectedUser.filter((id) => id !== userId)
  //     );
  //   } else {
  //     // Select user
  //     setSelectedUser((prevSelectedUser) => [...prevSelectedUser, userId]);
  //   }
  // };

  {
    /* For NON-OBJECT */
  }

  const handleSelectChange = (user) => {
    if (selectedUser.some((u) => u.user_id === user.user_id)) {
      // Deselect user (remove the object with matching user_id)
      setSelectedUser((prevSelectedUser) =>
        prevSelectedUser.filter((u) => u.user_id !== user.user_id)
      );
    } else {
      // Select user (add user object)
      setSelectedUser((prevSelectedUser) => [
        ...prevSelectedUser,
        {
          user_id: user.user_id,
          no_of_days: user.no_of_days, // Adding no_of_days
          bonus_amount: user.bonus_amount, // Adding bonus_amount
          status: user.status, // Adding status
          eligible_bonus_amount: user.eligible_bonus_amount,
        },
      ]);
    }
  };

  {
    /* For NON-OBJECT */
  }

  // const handleSelectAll = (e) => {
  //   if (e.target.checked) {
  //     // Select all users
  //     const allUserIds = bonusUserList.data.map((user) => user.user_id);
  //     setSelectedUser(allUserIds);
  //     setIsSelectedAll(true);
  //   } else {
  //     // Unselect all users
  //     setSelectedUser([]);
  //     setIsSelectedAll(false);
  //   }
  // }

  {
    /* For NON-OBJECT */
  }

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      // Select all users as array of objects with user_id
      const allUsers = bonusUserList?.data.map((user) => ({
        user_id: user.user_id,
        no_of_days: user.no_of_days,
        bonus_amount: user.bonus_amount,
        status: user.status,
        eligible_bonus_amount: user.eligible_bonus_amount,
      }));
      setSelectedUser(allUsers);
      setIsSelectedAll(true);
    } else {
      // Unselect all users
      setSelectedUser([]);
      setIsSelectedAll(false);
    }
  };

  {
    /* For NON-OBJECT */
  }

  // // Watch for changes in the selectedUser array to toggle isSelectedAll
  // useEffect(() => {
  //   if (selectedUser.length === bonusUserList.data.length) {
  //     setIsSelectedAll(true); // All users are selected
  //   } else {
  //     setIsSelectedAll(false); // Not all users are selected
  //   }
  // }, [selectedUser, bonusUserList.data.length]);

  {
    /* For NON-OBJECT */
  }

  useEffect(() => {
    // Get all user IDs from the bonusUserList
    const allUserIds = bonusUserList?.data.map((user) => user.user_id);
    // Check if all user IDs are present in selectedUser
    // const allSelected = selectedUser.every((selected) =>
    //   allUserIds.includes(selected.user_id)
    // );
    // Check if all user IDs are present in selectedUser
    const allSelected =
      selectedUser.length > 0 &&
      allUserIds.length === selectedUser.length &&
      selectedUser.every((selected) => allUserIds.includes(selected.user_id));

    setIsSelectedAll(allSelected); // Set isSelectedAll based on the presence of all user IDs
  }, [selectedUser, bonusUserList?.data]);
  // console.log(bonusUserList?.total_no_of_days);
  const totaldays = bonusUserList?.total_no_of_days;
  return (
    <>
      <p className="font-semibold my-5 text-lg">Total Days: {totaldays}</p>
      <h2 className=' text-xl mb-5 font-semibold'>Select Employee</h2>
      <table class="w-full">
        <thead>
          <tr class="bg-[color:var(--color1)] text-center">
            <th class="w-10 text-xs font-semibold text-white py-3 lg:py-2 px-3 lg:px-4 border-l border-transparent">
              <input
                type="checkbox"
                className="mt-2"
                checked={isSelectedAll}
                onChange={handleSelectAll}
              />
            </th>
            <th class="  text-xs font-semibold text-white py-3 lg:py-2 px-3 lg:px-4 border-l border-transparent">
              Employee Name
            </th>
            <th class=" w-20  text-xs font-semibold text-white py-3 lg:py-2 px-3 lg:px-4">
              No. of Days
            </th>
            <th class="  text-xs font-semibold text-white py-3 lg:py-2 px-3 lg:px-4">
              Bonus Amount
            </th>
            <th class="  text-xs font-semibold text-white py-3 lg:py-2 px-3 lg:px-4">
              Eligible Bonus Amount
            </th>
            <th class="  text-xs font-semibold text-white py-3 lg:py-2 px-3 lg:px-4">
              Status
            </th>
            <th class="  text-xs font-semibold text-white py-3 lg:py-2 px-3 lg:px-4">
              Base Salary
            </th>
            <th class="  text-xs font-semibold text-white py-3 lg:py-2 px-3 lg:px-4">
              Gross Salary
            </th>
            {/* <th class="  text-xs font-semibold text-white py-3 lg:py-2 px-3 lg:px-4">
              Action
            </th> */}
          </tr>
        </thead>
        <tbody>
          {bonusUserList?.data?.length > 0 ? (
            bonusUserList?.data?.map((user, index) => (
              <tr
                key={index}
                className="bg-gray-200 hover:bg-gray-100 cursor-pointer"
              >
                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">
                  <input
                    type="checkbox"
                    // checked={selectedUser.includes(user?.user_id)}  ----> {/* For NON-OBJECT */}
                    checked={selectedUser.some(
                      (u) => u.user_id === user.user_id
                    )}
                    // onChange={() =>
                    //   handleSelectChange(user?.user_id) ----> {/* For NON-OBJECT */}
                    // }
                    onChange={() => handleSelectChange(user)}
                  />
                </td>
                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                  {user?.employee_name}
                </td>
                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                  {user?.no_of_days}
                </td>
                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                  {user?.bonus_amount}
                </td>
                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                  {user?.eligible_bonus_amount}
                </td>
                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                  {user?.status}
                </td>
                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                  {user?.base_salary}
                </td>
                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                  {user?.gross_salary}
                </td>
                {/* <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                  <button className="btn btn-primary">Action</button>
                </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center py-3">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
});

export default BonusForm;
