import { useMutation } from "@tanstack/react-query";
import axios from "../api/newAxios";
import Cookies from "universal-cookie";
import { TOKEN } from "../config";
const cookies = new Cookies();

function useDownloadGet({ url, filename, onError }) {

    const reportDownload = useMutation(() => downloadReports(), {
        onSuccess: (data) => {
            const href = URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.href = href;
            a.download = filename;
            a.click();
        },
        onError: () => {
            onError?.()
        }
    });

    const downloadReports = async () => {
        try {
            let TOKEN_NAME = cookies.get(TOKEN);
            let res = await axios({
                method: "get",
                url: `/${url}`,
                responseType: 'blob',
                headers: { Authorization: TOKEN_NAME ? `Bearer ${TOKEN_NAME}` : '' }
            });
            return res.data;
        } catch (error) {
            throw new Error('Failed to download file');
        }
    };

    return { reportDownload };
}

export default useDownloadGet;
