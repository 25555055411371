import React, { useEffect, useRef, useState } from "react";
import {
  getEmployeeDetail,
  getUserDocumentsList,
  postEmployeeDetailsDelete,
  userDocumentAdd,
  userDocumentRemove,
} from "../../api/employeeDetails";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Layout from "../../components/layout/Layout";
import {
  EyeIcon,
  PencilAltIcon,
  TrashIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import Modal from "@mui/material/Modal";
import Modals from "../../components/modal/Modals";
import spinner from "../../assets/images/spinner.gif";
import ZoomImage from "../../components/ZoomImage";
import { downloadUserReport } from "../../api";
import Pagination from "../../components/pagination";
// import EmployeeTab from './EmployeeTab';
import useGet from "../../hooks/read/useGet";
import toast, { Toaster } from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import useDownloadFile from "../../hooks/useDownloadFile";
import { config } from "../../config";
import useDownloadGet from "../../hooks/useDownloadGet";
import Loader from "../../components/Loader";

function BonusListing() {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  let initialFilter = {
    search_query: "",
    per_page: 20,
    page_no: searchParams.get("page_no") || 0,
  };
  const [filterObject, setFilterObject] = useState(initialFilter);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const eduFileRef = useRef(null);
  const [limit, setLimit] = useState(20);
  // const [isLoading, setIsLoading] = useState(true);
  const [employeeData, setemployeeData] = useState([]);
  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [pageno, setPageNo] = useState(0);
  const handleDelete = () => {
    postEmployeeDetailsDelete(employeeId)
      .then((res) => {
        if (res.status === 200) {
          setIsConfirmDeleteModal(false);
          queryClient.invalidateQueries("employeelist");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };
  let reportState = {
    bonus_id: "",
  };
  const [report, setReportState] = useState(reportState);

  const [removeDocumentConfirmModal, setRemoveDocumentConfirmModal] =
    useState(false);
  const [userId, setUserId] = useState("");
  const [doumentId, setDocumentId] = useState("");
  const [docType, setDocType] = useState("");
  const [isUploadingFile, setIsuploadingFile] = useState(false);
  const initialEmployeeState = {
    page_no: searchParams.get("page_no") || 0,
    per_page: searchParams.get("per_page") || 10,
    search_query: searchParams.get("search_query") || "",
    //  total_count:searchParams.get("total_count") ,
    type: "employee",
  };
  const {
    list: employeeList,
    paramsObject,
    setGetListParams,
  } = useGet({
    url: "bonus_listing",
    initialData: initialEmployeeState,
    onSuccess: () => {
      scrollToTop();
      // toast.success('Employees Fetched Successfully')
    },
    onError: () => {
      toast.error(
        "There Was An Error While Fetching The Data Please Try Again Later"
      );
    },
  });

  const handleFetchUserDocuments = (id) => {
    setUserId(id);
    getUserDocumentsList(id)
      .then((res) => {
        if (res.status === 200) {
          setDocuments(res.data.data);
          setIsOpenDocumentModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleRemoveDocumentConfirmModal = (id) => {
    setDocumentId(id);
    setIsOpenDocumentModal(false);
    setRemoveDocumentConfirmModal(true);
  };

  const handleDeleteDocument = () => {
    userDocumentRemove(doumentId)
      .then((res) => {
        if (res.status === 200) {
          setRemoveDocumentConfirmModal(false);
          setIsOpenDocumentModal(true);
          handleFetchUserDocuments(userId);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddEduDoc = () => {
    if (!docType || docType === "" || docType === undefined)
      return alert("Please select document type first");
    eduFileRef.current.click();
  };
  const handleFileUpload = (e) => {
    setIsuploadingFile(true);
    let file = e.target.files;
    userDocumentAdd({ userId, file, docType })
      .then((res) => {
        if (res.status === 200) {
          setIsuploadingFile(false);
          setIsOpenDocumentModal(true);
          handleFetchUserDocuments(userId);
        }
      })
      .catch((err) => {
        setIsuploadingFile(false);
        alert("Something went wrong while uploading please try again");
      });
  };
  const [isDownloading, setIsDownloading] = useState(false);
  // const handleDownloadReport = () => {
  //    setIsDownloading(true)
  //    downloadUserReport()
  //       .then((res) => {
  //          // console.log(res)
  //          // create file link in browser's memory
  //          const href = URL.createObjectURL(res.data);

  //          let fileName = `User_Report.xlsx`
  //          // create "a" HTLM element with href to file & click
  //          const link = document.createElement('a');
  //          link.href = href;
  //          link.setAttribute('download', fileName); //or any other extension
  //          document.body.appendChild(link);
  //          link.click();
  //          document.body.removeChild(link);
  //       })
  //       .catch(() => { })
  //       .finally(() => {
  //          setIsDownloading(false)
  //       })
  // }



  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      page_number: val.selected,
    };
    setGetListParams(data);
    setSearchParams(data);
  };
  const handleSearch = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {
      ...paramsObject,
      [name]: value,
    };
    setGetListParams(data);
    setSearchParams(data);
  };
  const [bonusId, setBonusId] = useState(null);
  const [filename, setFilename] = useState("");
  const [loadingStates, setLoadingStates] = useState({}); // Track loading states for each bonus_id
  const [loadingConsolidateStates, setLoadingConsolidateStates] = useState({}); // Track loading states for each bonus_id

  // const { reportDownload } = useDownloadFile({
  //   url: 'bonus_report',
  //   filename,
  //   onError: () => {
  //     toast.error('There was an error while downloading the file')
  //   }
  // })
  const { reportDownload } = useDownloadFile({
    url: 'bonus_report',
    filename,
    onError: () => toast.error('There was an error while downloading the file'),
  });

  // Handle report download
  const handleDownloadReport= (bonus_id,from_date, to_date) => async (e) => {
    e.preventDefault();
    setLoadingStates((prev) => ({ ...prev, [bonus_id]: true }));
    const fromDateString = from_date
        ? new Date(from_date * 1000).toLocaleDateString("en-GB")
        : "N/A";
      const toDateString = to_date
        ? new Date(to_date * 1000).toLocaleDateString("en-GB")
        : "N/A";
  

      // Set the filename dynamically
      const reportFilename = `Bonus Report for ${fromDateString} - ${toDateString}.xlsx`;
      setFilename(reportFilename); // Update the filename state
    try {
      await reportDownload.mutateAsync({ bonus_id}); // Pass report data including bonus_id
      toast.success("File Downloaded Successfully");
      setLoadingStates((prev) => ({ ...prev, [bonus_id]: false }));
    } catch (error) {
      console.error(error);
      setLoadingStates((prev) => ({ ...prev, [bonus_id]: false }));
    }
  };
  const { reportDownload:consolidatedreport } = useDownloadFile({
    url: 'bonus_report_monthly_count',
    filename,
    onError: () => toast.error('There was an error while downloading the file'),
  });

  // Handle report download
  const handleDownloadConsolidateReport= (bonus_id,from_date, to_date) => async (e) => {
    e.preventDefault();
    setLoadingConsolidateStates((prev) => ({ ...prev, [bonus_id]: true }));

    const fromDateString = from_date
        ? new Date(from_date * 1000).toLocaleDateString("en-GB")
        : "N/A";
      const toDateString = to_date
        ? new Date(to_date * 1000).toLocaleDateString("en-GB")
        : "N/A";
  
      // Set the filename dynamically
      const reportFilename = ` Consolidated Bonus Report for ${fromDateString} - ${toDateString}.xlsx`;
      setFilename(reportFilename); // Update the filename state
    try {
      await consolidatedreport.mutateAsync({ bonus_id}); // Pass report data including bonus_id
      toast.success("File Downloaded Successfully");
      setLoadingConsolidateStates((prev) => ({ ...prev, [bonus_id]: false }));
    } catch (error) {
      console.error(error);
      setLoadingConsolidateStates((prev) => ({ ...prev, [bonus_id]: false }));
    }
  };
  // const { reportDownload } = useDownloadGet({
  //   url: bonusId ? `bonus_report?bonus_id=${bonusId}` : "",
  //   filename, // Use the dynamic filename based on dates
  //   onError: () => {
  //     toast.error("There was an error while downloading the file");
  //     // setIsLoading(false); // Stop loading on error
  //   },
  //   onSuccess: () => {
  //     // setIsLoading(false); // Stop loading on success
  //   },
  // });
  // const { reportDownload:consolidatedreport } = useDownloadGet({
  //   url: bonusId ? `bonus_report_monthly_count?bonus_id=${bonusId}` : "",
  //   filename, // Use the dynamic filename based on dates
  //   onError: () => {
  //     toast.error("There was an error while downloading the file");
  //     // setIsLoading(false); // Stop loading on error
  //   },
  //   onSuccess: () => {
  //     // setIsLoading(false); // Stop loading on success
  //   },
  // });

  // const handleDownloadReport = (bonus_id, from_date, to_date) => async (e) => {
  //   e.preventDefault();
  //   setBonusId(bonus_id); // Set the bonus_id when the button is clicked
  //   setLoadingStates((prev) => ({ ...prev, [bonus_id]: true }));

  //   // Format the dates for the filename
  //   const fromDateString = from_date
  //     ? new Date(from_date * 1000).toLocaleDateString("en-GB")
  //     : "N/A";
  //   const toDateString = to_date
  //     ? new Date(to_date * 1000).toLocaleDateString("en-GB")
  //     : "N/A";

  //   // Set the filename dynamically
  //   const reportFilename = `Bonus Report for ${fromDateString} - ${toDateString}.xlsx`;
  //   setFilename(reportFilename); // Update the filename state
  //   try {
  //     await reportDownload.mutateAsync(); // Trigger the download process
  //   } catch (error) {
  //     toast.error("There was an error while downloading the file");
  //   } finally {
  //     toast.success("File Downloaded Successfully");
  //     // Reset the loading state for the specific button to false
  //     setLoadingStates((prev) => ({ ...prev, [bonus_id]: false }));
  //   }
  // };
  // const handleDownloadConsolidateReport = (bonus_id, from_date, to_date) => async (e) => {
  //   e.preventDefault();
  //   setBonusId(bonus_id); // Set the bonus_id when the button is clicked
  //   setLoadingConsolidateStates((prev) => ({ ...prev, [bonus_id]: true }));

  //   // Format the dates for the filename
  //   const fromDateString = from_date
  //     ? new Date(from_date * 1000).toLocaleDateString("en-GB")
  //     : "N/A";
  //   const toDateString = to_date
  //     ? new Date(to_date * 1000).toLocaleDateString("en-GB")
  //     : "N/A";

  //   // Set the filename dynamically
  //   const reportFilename = `Consolidated Bonus Report for ${fromDateString} - ${toDateString}.xlsx`;
  //   setFilename(reportFilename); // Update the filename state
  //   try {
  //     await consolidatedreport.mutateAsync(); // Trigger the download process
  //   } catch (error) {
  //     toast.error("There was an error while downloading the file");
  //   } finally {
  //     toast.success("File Downloaded Successfully");
  //     // Reset the loading state for the specific button to false
  //     setLoadingConsolidateStates((prev) => ({ ...prev, [bonus_id]: false }));
  //   }
  // };
  const handleNavigateToBonusDetail = (id, username) => {
    // navigate(`/employee-salary-details/${id}/${username}`)
    navigate(`/single-bonus-details/${id}`)
}

  return (
    <Layout>
      <Toaster />
      <div className="mb-12">
        <div className=" container">
          <div className="flex items-center justify-between px-5">
            <div className=" w-[400px]">
              <input
                type="search"
                placeholder="Search By Name..."
                name="search_query"
                className="input"
                value={paramsObject.search_query}
                onChange={handleSearch}
              />
            </div>
            <div className="space-x-4 px-4 py-4 text-end">
              <Link
                to={"/add-bonus"}
                className=" bg-[color:var(--color1)] inline-block text-white rounded px-4 py-4"
              >
                Add Bonus
              </Link>
              {/* <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-4">Download Report</button> */}
            </div>
          </div>
        </div>
        {/* <EmployeeTab /> */}
        {employeeList.isLoading || isDownloading ? (
          <div className=" w-16 h-16 mx-auto mt-8">
            <img src={spinner} alt="spinner" className="w-full h-full" />
          </div>
        ) : (
          <>
            {employeeList.data?.data?.length <= 0 ? (
              <div className="text-center">
                <p className=" text-gray-700 text-3xl">No Users To Show</p>
              </div>
            ) : (
              <div class="max-w-full overflow-x-auto px-4 mt-8">
                <table class="table-auto w-full">
                  <thead>
                    <tr class=" bg-[color:var(--color1)] text-center">
                      <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                        Title
                      </th>
                      {/* <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Mobile Number</th> */}
                      <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        Employee Count
                      </th>
                      <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        From Date
                      </th>
                      <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        To Date
                      </th>
                      <th class="w-[200px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        Total no. of days
                      </th>

                      <th class="w-[400px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4border-r border-transparent">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeList.data?.data?.map((data, index) => {
                      const {
                        title,
                        bonus_id,
                        employee_count,
                        is_hibernate,
                        from_date,
                        to_date,
                        email,
                        total_no_of_days,
                        id,
                        type_of_employee,
                      } = data;
                      return (
                        <tr
                        onClick={() => handleNavigateToBonusDetail(bonus_id)}
                          key={index}
                          className="bg-gray-200 hover:bg-gray-100 cursor-pointer"
                        >
                          <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-l border-[#E8E8E8]">
                            {title}
                          </td>
                          {/* <td class=" text-center text-dark font-medium text-sm py-3 px-2 bg-white border-b border-[#E8E8E8]">{phone}</td> */}
                          <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                            {employee_count}
                          </td>
                          <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                            {from_date
                              ? new Date(from_date * 1000).toLocaleDateString(
                                  "en-GB"
                                )
                              : "N/A"}
                          </td>
                          <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                            {to_date
                              ? new Date(to_date * 1000).toLocaleDateString(
                                  "en-GB"
                                )
                              : "N/A"}
                          </td>
                          <td class="text-center text-dark font-medium text-sm py-3 px-2 w-[100px]  border-b border-[#E8E8E8]">
                            {total_no_of_days || "--"}
                          </td>

                          <td class="text-center  flex text-dark font-medium text-xs gap-2 py-3 px-2  border-b border-r border-[#E8E8E8]">
                            <div
                              className=" flex justify-center space-x-1"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <button
                                onClick={handleDownloadConsolidateReport(
                                  bonus_id,
                                  from_date,
                                  to_date
                                )}
                                className="bg-[color:var(--color1)] text-white rounded px-4 py-4 flex items-center"
                                disabled={loadingConsolidateStates[bonus_id] || consolidatedreport.isLoading}
                              >
                                {loadingConsolidateStates[bonus_id] ? (
                            <span className=" max-h-3"><Loader/></span> // Add your loader styling here
                          ) : (
                            "Download Consolidate Report"
                          )}
                              </button>
                            </div>
                            <div
                              className=" flex justify-center space-x-1"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <button
                                onClick={handleDownloadReport(
                                  bonus_id,
                                  from_date,
                                  to_date
                                )}
                                className="bg-[color:var(--color1)] text-white rounded px-4 py-4 flex items-center"
                                disabled={loadingStates[bonus_id] || reportDownload.isLoading}
                              >
                                {loadingStates[bonus_id] ? (
                            <span className=" max-h-3"> <Loader/></span> // Add your loader styling here
                          ) : (
                            "Download Report"
                          )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            <div className="px-5 mt-5">
              {console.log(employeeList?.data?.total_count)}
              <Pagination
                currentPage={+paramsObject?.page_no}
                lengthofItems={employeeList?.data?.total_count}
                limit={paramsObject?.per_page}
                onPageChange={handlePageClick}
              />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default BonusListing;
