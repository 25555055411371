import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useRead from '../../hooks/useRead'
import AdvanceEmploymentEditing from './AdvanceEmploymentEditing'
import { Toaster } from 'react-hot-toast'
import HibernationDrawer from './HibernationDrawer'

const SingleEmployeeDetail = () => { 
    const [isOpenAdvanceEmployment, setIsOpenAdvanceEmployment] = useState(false)
    const [isOpenHibernationDrawer, setIsOpenHibernationDrawer] = useState(false)
    const { id, username } = useParams()
    const navigate = useNavigate()
    const { list } = useRead({
        url: 'employeedetails_edit',
        initialData: { id }
    })
    const handleOpenAdvanceMapping = () => {
        setIsOpenAdvanceEmployment(true)
    }
    const handleOpenHibernationDrawer= () => {
        setIsOpenHibernationDrawer(true)
    }
    return (
        <Layout>
            <Toaster />
            <div className=' px-4 py-4 flex justify-between sticky bg-white top-16 shadow-lg'>
                <button onClick={() => navigate(-1, { replace: true })} className='flex items-center hover:underline mt-4'>
                    <ChevronLeftIcon className='w-6 h-6' />
                    <span className='font-semibold'>Back</span>
                </button>
                <div className=' space-x-4'>
                    {/* <Link to={`/employee-salary/${id}/${username}`} className='px-5 py-2 bg-yellow-300 rounded font-semibold'>Salary Details</Link> */}
                    <button onClick={handleOpenHibernationDrawer} className='px-5 py-2 bg-green-300 rounded font-semibold text-sm'>
                        {/* {list.data?.data?.type === 'resigned' 
                        ? 'View Shift' 
                        : 'Assign New Shift'}   */}
                     Hibernation
                    </button>
                    <button onClick={handleOpenAdvanceMapping} className='px-5 py-2 bg-orange-300 rounded font-semibold text-sm'>
                        {list.data?.data?.type === 'resigned' 
                        ? 'View Shift' 
                        : 'Assign New Shift'}
                    </button>
                    <Link to={`/employee-salary-details/${id}?mode=${list.data?.data?.type}`} className='px-5 py-2 bg-yellow-300 rounded font-semibold'>Salary Details</Link>
                </div>
            </div>
            <div className='px-4 space-y-4 my-5'>
                <div>
                    <div className='grid grid-cols-2 gap-5'>
                        <table className='w-full'>
                            <thead>
                                <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Personal Details</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>First Name</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.first_name || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Last Name</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.last_name || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Email</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.email || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Date Of Birth</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.DOB || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Gender</td>
                                    <td className='border py-3 px-4 capitalize'>{list?.data?.data.gender || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Mobile Number</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.mobile_number || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Alternate Number</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.home_phone || '----'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div className='grid grid-cols-2 gap-5'>
                        <table className='w-full'>
                            <thead>
                                <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Current Address Details</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Current Address Line 1</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.address_1 || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Current Address Line 2</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.address_2 || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Pincode</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.pincode || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>City</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.city || '----'}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className='w-full'>
                            <thead>
                                <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Permanent Address Details</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Permanent Address Line 1</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.permanent_address_1 || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Permanent Address Line 2</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.permanent_address_2 || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Pincode</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.permanent_city || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>City</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.permanent_state || '----'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div className='grid grid-cols-2 gap-5'>
                        <table className='w-full'>
                            <thead>
                                <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Bank Details</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Bank Name</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.bank_name || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Account Number</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.account_number || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>IFSC Code</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.ifsc_code || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Pan Number</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.pan_number || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Aadhar Number</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.aadhar_number || '----'}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className='w-full'>
                            <thead>
                                <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Work Details</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Educational Qualification</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.qualification || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Work Experience</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.work_experience || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Designation</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.designation || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Department</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.department || '----'}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div>
                    <div className='grid grid-cols-2 gap-5'>
                        <table className='w-full'>
                            <thead>
                                <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Statutory Components</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Employees' Provident Fund</td>
                                    <td className='border py-3 px-4'>{list?.data?.data?.statutory?.epf?.isEpf ? 'Applicable' : 'Not Applicable'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>PF Number</td>
                                    <td className='border py-3 px-4'>{list?.data?.data?.statutory?.epf?.pf_account_number || '----'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Professional Tax</td>
                                    <td className='border py-3 px-4'>{list?.data?.data.statutory.isPt ? 'Applicable' : 'Not Applicable'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Employees' State Insurance</td>
                                    <td className='border py-3 px-4'>{list?.data?.data?.statutory?.esi?.isEsi ? 'Applicable' : 'Not Applicable'}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>ESIC Number</td>
                                    <td className='border py-3 px-4'>{list?.data?.data?.statutory?.esi?.esi_number || '----'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div>
                    <div className='grid grid-cols-2 gap-5'>
                        <table className='w-full'>
                            <thead>
                                <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Salary Details</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Gross Salary</td>
                                    <td className='border py-3 px-4'>Employee Name</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Professional Tax</td>
                                    <td className='border py-3 px-4'>Designation</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Net Salary</td>
                                    <td className='border py-3 px-4'>Department</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Total Leave</td>
                                    <td className='border py-3 px-4'>listData?.date_of_joining</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </div>
            <AdvanceEmploymentEditing mode={list.data?.data?.type} onClose={() => setIsOpenAdvanceEmployment(false)} open={isOpenAdvanceEmployment} />
            <HibernationDrawer mode={list.data?.data?.type} onClose={() => setIsOpenHibernationDrawer(false)} open={isOpenHibernationDrawer} />
        </Layout>
    )
}

export default SingleEmployeeDetail