import { ArrowLeftIcon } from '@heroicons/react/solid'
import { Drawer, Modal } from '@mui/material'
import React, { useState } from 'react'
import EmployeeAssignModule from '../../../components/EmployeeAssignModule'

const ShiftRotationDrawer = ({ open, onClose }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    let data = {
        users: [
            {
                name: 'Mukesh',
                id: 1
            },
            {
                name: 'Ronak',
                id: 2
            },
            {
                name: 'Khanti',
                id: 3
            },
        ],
        departments: [
            {
                name: 'IT',
                id: 4
            },
            {
                name: 'HR',
                id: 5
            },
            {
                name: 'SALES',
                id: 6
            },
        ]
    }
    let selected = [
        {
            name: 'Mukesh',
            id: 1
        },
        {
            name: 'Ronak',
            id: 2
        },
    ]
    const [selectedData, setSelectedData] = useState(selected)
    const handleGetSelectedValue = (value) => {
        setSelectedData(value)
    }
    return (
        <Drawer anchor={'right'} open={open} onClose={onClose}>
            <div className='h-full bg-white p-8' style={{ width: 'calc(100vw - 650px)' }}>
                <form className=' space-y-8'>
                    <div>
                        <div className='flex items-center space-x-3 pb-5 border-b border-gray-200'>
                            <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={onClose} />
                            <p className='text-gray-700 text-3xl font-semibold'>Shift Rotation</p>
                        </div>
                        <div className=' space-y-5 mt-5'>
                            <div>
                                <h3 className='text-2xl font-semibold py-3'>Scheduler Details</h3>
                                <div className=' space-y-4'>
                                    <div>
                                        <div className=''>
                                            <label className='label'>Schedule Name: </label>
                                            <input type="text" name='title' required={true} className='input' placeholder='Enter Schedule name ' />
                                        </div>
                                    </div>
                                    <div>
                                        <div className=''>
                                            <label className='label'>Schedule Frequency: </label>
                                            <div className='grid grid-cols-2 gap-4'>
                                                <select name="" className='input'>
                                                    <option value="">--Select--</option>
                                                    <option value="">Weekly</option>
                                                    <option value="">Monthly</option>
                                                    <option value="">Bi Weekly</option>
                                                </select>
                                                <select name="" className='input'>
                                                    <option value="">--Select--</option>
                                                    <option value="">Weekly</option>
                                                    <option value="">Monthly</option>
                                                    <option value="">Bi Weekly</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className=''>
                                            <label className='label'>Time of Schedule: </label>
                                            <input type="text" name='title' required={true} className='input' placeholder='Enter Schedule name ' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=' space-y-5 mt-5'>
                            <div>
                                <h3 className='text-2xl font-semibold py-3'>Shift Rotation details</h3>
                                <div className=' space-y-4'>
                                    <div>
                                        <div className=''>
                                            <label className='label'>Applicable Period: </label>
                                        </div>
                                    </div>
                                    <div>
                                        {/* <div className=''>
                                            <label className='label'>Applicable For: </label>
                                        </div> */}
                                        <div className='flex items-center flex-wrap mb-4'>
                                            <label className='label mr-2 mb-0'>Applicable For: </label>
                                            <div className=' space-x-2'>
                                                {selectedData.map((item, itemIdx) => {
                                                    return <label key={itemIdx} className=' bg-amber-100 text-amber-700 px-4 rounded-md py-1 text-sm capitalize'>{item.name}</label>
                                                })}
                                            </div>
                                        </div>
                                        <button onClick={() => setIsModalOpen(true)} type='button' className=' bg-cyan-100 text-cyan-800 px-8 py-1 font-semibold rounded-md'>Add</button>
                                    </div>
                                    <div>
                                        <div className=''>
                                            <label className='label'>Shift Rotation: </label>
                                            <input type="text" name='title' required={true} className='input' placeholder='Enter Schedule name ' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-10 space-x-3'>
                            <button className=' rounded-md bg-color1 px-8 py-2 text-white'>
                                <div className='flex items-center justify-center space-x-2'>
                                    <span className=' font-semibold'>Submit</span>
                                </div>
                            </button>
                            <button className=' rounded-md border border-gray-300 px-8 py-2 text-gray-500'>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-10 rounded-md min-h-[28rem] h-[28rem] overflow-hidden'>
                    <EmployeeAssignModule
                        options={data}
                        selected={selectedData}
                        onClose={() => setIsModalOpen(false)}
                        onChange={handleGetSelectedValue}
                    />
                </div>
            </Modal>
        </Drawer>
    )
}

export default ShiftRotationDrawer