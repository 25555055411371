import axios from "axios";

import Cookies from "universal-cookie";
import { TOKEN, config } from "../config";
const cookies = new Cookies();

export const getEmployeeDetail = async (pageno = 0, limit = 50,search_query) => {
    let token = cookies.get(TOKEN);
    var res = await axios({ method: "GET", url: `${config.apiurl}employeelist?page_number=${pageno}&limit=${limit}&search_query=${search_query}`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res

};
export const postEmployeeDetails = async (data) => {
    let { work_documents: educationFile, education_certificates: workDoc } = data
    var form_data = new FormData();
    for (var key in data) {
        if (key === 'statutory') {
            form_data.append(key, JSON.stringify(data['statutory']));
        } else {
            form_data.append(key, data[key]);
        }
    }
    if (educationFile?.target?.files.length > 0) {
        for (let i = 0; i < educationFile.target.files.length; i++) {
            form_data.append('education_certificates[]', educationFile.target.files[i]);
        }
    }
    if (workDoc?.target?.files.length > 0) {
        for (let j = 0; j < workDoc.target.files.length; j++) {
            form_data.append('work_documents[]', workDoc.target.files[j]);
        }
    }
    let token = cookies.get(TOKEN);
    var res = await axios({ method: "POST", url: `${config.apiurl}employeedetails`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};
export const getIndividualEmployeeDetail = async (id) => {
    let token = cookies.get(TOKEN);
    let fd = new FormData()
    fd.append('id', id)
    var res = await axios({ method: "POST", url: `${config.apiurl}employeedetails_edit`, data: fd, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};
export const getIndividualHibernationDetail = async (id) => {
    let token = cookies.get(TOKEN);
    let fd = new FormData()
    fd.append('id', id)
    var res = await axios({ method: "POST", url: `${config.apiurl}fetch_single_user_hibernation_data`, data: fd, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};
export const updateEmployeeDetail = async (data) => {
    let { work_documents: educationFile, education_certificates: workDoc } = data
    var form_data = new FormData();
    for (var key in data) {
        if (key === 'statutory') {
            form_data.append(key, JSON.stringify(data['statutory']));
        } else {
            form_data.append(key, data[key]);
        }
    }
    let token = cookies.get(TOKEN);
    var res = await axios({ method: "POST", url: `${config.apiurl}employeedetails_update`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};
export const postEmployeeDetailsDelete = async (id) => {
    let token = cookies.get(TOKEN);
    var fd = new FormData();
    fd.append('id', id);
    var res = await axios({ method: "POST", url: `${config.apiurl}employeedetails_delete`, data: fd, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};

export const getLeaveAppliactionList = async () => {
    let token = cookies.get(TOKEN);
    var res = await axios({ method: "GET", url: `${config.apiurl}leaveapplicationlist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}


export const getUserDocumentsList = async (id) => {
    var form_data = new FormData();
    let token = cookies.get(TOKEN);
    form_data.append('id', id);
    var res = await axios({ method: "POST", url: `${config.apiurl}uploadfilelist`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}

export const userDocumentAdd = async (data) => {
    const { userId, file, docType } = data
    var form_data = new FormData();
    let token = cookies.get(TOKEN);
    form_data.append('user_id', userId);
    form_data.append('document_type', docType);
    if (file.length > 0) {
        for (let i = 0; i < file.length; i++) {
            form_data.append('document[]', file[0]);
        }
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}uploadfileadd`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}
export const userDocumentRemove = async (id) => {
    var form_data = new FormData();
    let token = cookies.get(TOKEN);
    form_data.append('media_id', id);
    var res = await axios({ method: "POST", url: `${config.apiurl}uploadfiledelete`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}

export const getAppoinmentLetterList = async () => {
    let token = cookies.get(TOKEN);
    var res = await axios({ method: "GET", url: `${config.apiurl}appointmentletterlist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}


export const setAppoinmentLetter = async (data) => {
    let token = cookies.get(TOKEN);
    var form_data = new FormData();
    form_data.append('user_id', data.id);
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}appointmentletterpdf`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}
export const setConfirmationLetter = async (data) => {
    let token = cookies.get(TOKEN);
    var form_data = new FormData();
    form_data.append('user_id', data.id);
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}confirmationletterpdf`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
}

export const getSalaryList = async (data) => {
    let token = cookies.get(TOKEN);
    var form_data = new FormData();
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}getMemberPayouts`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
}
export const getAttendanceReportList = async (data) => {
    let token = cookies.get(TOKEN);
    var form_data = new FormData();
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}fetchattendancedatalisting`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
}

 export const getFetchFile = async (data) => {
    let token = cookies.get(TOKEN);
    var form_data = new FormData();
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}fetchattendancedatewise`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
}
// export const getForm16List = async (data) => {
//     let token = cookies.get(TOKEN); // Ensure 'TOKEN' matches your actual token name
  
//     // Format month_year correctly if it's a Date object
//     const formatYearRange = (date) => {
//       if (!(date instanceof Date)) {
//         return date; // Return as is if it's not a Date object
//       }
//       const startYear = date.getFullYear();
//       const endYear = startYear + 1;
//       return `${startYear}-${endYear}`;
//     };
  
//     // Ensure month_year is in the correct format
//     if (data.month_year instanceof Date) {
//       data.month_year = formatYearRange(data.month_year);
//     }
  
//     // Build the query string parameters
//     const queryString = new URLSearchParams(data).toString();
  
//     try {
//       const url = `${config.apiurl}form_16_employee_listing?${queryString}`;
//       const res = await axios.get(url, {
//         headers: { Authorization: token ? `Bearer ${token}` : '' },
//       });
      
//       return res.data; // Return only the data from the response
//     } catch (error) {
//       console.error('Error fetching Form 16 list:', error);
//       throw error;
//     }
//   };
  
export const getForm16List = async (data) => {
    let token = cookies.get(TOKEN);
    var form_data = new FormData();
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}form_16_employee_listing`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
}
export const getFormData = async (data) => {
    try {
      // Retrieve token from cookies
      const token = cookies.get(TOKEN);
  
      // Create FormData object (optional, modify if data format differs)
      const formData = new FormData();
  
      // Append data parameters (modify based on your API requirements)
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
  
      // Construct the API URL (replace with your actual endpoint)
      const url = `${config.apiurl}get_form_16_data`; // Replace with your endpoint
  
      // Make the POST request with Axios
      const response = await axios.post(url, formData || data, { // Adjust data based on API format
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });
  
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Re-throw the error for handling in the calling component
    }
  };
  