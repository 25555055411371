import axios from "axios";
import { TOKEN, config } from "../config";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const loginUser = async (data) => {
    var res = await axios({ method: "POST", url: `${config.apiurl}loginuser`, data: data })
    return await res
};
export const resetPassword = async (id,password) => {
    let token = cookies.get(TOKEN);
    var form_data = new FormData();    
    form_data.append('id', id);
    form_data.append('password', password);
    var res = await axios({ method: "POST", url: `${config.apiurl}passwordreset`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};

export const registerCompany = async (data) => {
    let fd = new FormData()
    for (var key in data) {
      fd.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}registerCompany`, data: fd })
    return await res
}

export const forgotPassword = async (data) => {
    let fd = new FormData()
    for (var key in data) {
      fd.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}forgot_password`, data: fd })
    return await res
}

export const resetPasswordAgain = async (data) => {
  let fd = new FormData()
  for (var key in data) {
    fd.append(key, data[key]);
  }
  var res = await axios({ method: "POST", url: `${config.apiurl}reset_password`, data: fd })
  return await res
}


