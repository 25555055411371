import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom'

const EditLeaves = () => {
    const initialState = {
        fromDate: '',
        toDate: '',
        status: ''
    }
    const [form, setForm] = useState(initialState)
    const navigate = useNavigate();
    const handlePushBack = () => {
        navigate(-1)
    }
    const handleSetForm = ({ value, name }) => {
        setForm({ ...form, [name]: value });
    }
    const handleSubmitLeaveInformation = (e) => {
        e.preventDefault()
        console.log(form);
    }
    return (
        <Layout>
            <div className='px-5'>
                <div className=' relative'>
                    <div className=' absolute left-5 top-1/2 -translate-y-1/2 cursor-pointer' onClick={handlePushBack}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                    </div>
                    <h1 className='text-4xl text-center text-gray-600 py-4 border-b border-gray-300'>Applied Leave Information</h1>
                </div>
                <div className='py-4'>
                    <div className=' space-y-2'>
                        <h2 className=' font-semibold text-3xl text-gray-700'>Username:</h2>
                        <h4 className=' font-medium text-lg text-gray-700'>Department:</h4>
                    </div>
                    <div className=' py-4'>
                        <form className=' space-y-4' onSubmit={handleSubmitLeaveInformation}>
                            <div className='grid grid-cols-2 gap-4'>
                                <div className="">
                                    <label htmlFor="from date" className='label'>From Date:</label>
                                    <DatePicker selected={form.fromDate} className='input' dateFormat="dd/MM/yyyy" onChange={(value) => handleSetForm({ value, name: 'fromDate' })} />
                                </div>
                                <div className="">
                                    <label htmlFor="to date" className='label'>To Date:</label>
                                    <DatePicker selected={form.toDate} className='input' dateFormat="dd/MM/yyyy" onChange={(value) => handleSetForm({ value, name: 'toDate' })} />
                                </div>
                            </div>
                            <div className="">
                                <label htmlFor="status" className='label'>Status:</label>
                                <select value={form.status} className='input' name="status" onChange={(e) => handleSetForm({ value: e.target.value, name: 'status' })}>
                                    <option value="">--Select Status--</option>
                                    <option value="approved">Approved</option>
                                    <option value="reject">Reject</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="status" className='label'>Remark:</label>
                                <textarea name="remark" className=' min-h-[10rem] input' placeholder='Enter remark'></textarea>
                            </div>
                            <div>
                                <button type='submit' className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Verify Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default EditLeaves