import { useState } from "react"
import { useQuery } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { TOKEN, config } from "../../config";
import axios from "../../api/newAxios";
import { generateURL } from "../../utility";
const cookies = new Cookies();

const useGet = ({
    url, initialData, onSuccess,onError
}) => {
    const [paramsObject, setGetListParams] = useState(initialData)
    const list = useQuery([url, paramsObject], () => getUsers(paramsObject), {
        refetchOnWindowFocus: false,
        onSuccess(data) {
            onSuccess?.(data)
        },
        onError(err) {
            onError?.(err)
        },
        onSettled() {
            // onSettled?.()
        }
    })
    const getUsers = async (paramsObject) => {
        let token = cookies.get(TOKEN);
        const response = await axios({
            method: "GET",
            url: `/${url}${generateURL(paramsObject)}`,
            headers: { Authorization: token ? `Bearer ${token}` : '', }
        })
        return response.data
    }

    return { list, paramsObject, setGetListParams }
}

export default useGet