import { XIcon } from '@heroicons/react/solid'
import { Modal } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../components/Loader'
import ToastMessage from '../../components/toasts'
import SelectSearch from 'react-select-search';
import usePut from '../../hooks/usePut'
import OvertimeReimbursement from './OvertimeReimbursement'

const EditDepartment = ({ open, onClose, data, options, department_id }) => {
    const normalOtRef = useRef(null)
    const weekendOtRef = useRef(null)
    const [departmentName, setDepartmentName] = useState(data?.name)
    const [shift, setShift] = useState(data?.shift_id)
    const [otTypes, setOtTypes] = useState([])
    useEffect(() => {
        setDepartmentName(data?.name)
        setShift(data?.shift_id)
        setOtTypes(Object.keys(data?.otType));
    }, [data])
    const handleChangeDepartment = (value) => {
        setShift(value)
    }
    const handleSetCustomOption = () => {
        return options?.map(({ id, shiftName }) => ({ value: id, name: shiftName }))
    }
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setError] = useState(false)
    const { handleAuth } = usePut({
        url: 'department_update',
        refreshUrl: 'department_listing',
        onSuccess: () => {
            setIsSuccess(true)
        },
        onError: () => {
            setError(true)
        }
    })
    const handleSaveDepartment = () => {
        let normal = otTypes.includes("normal") ? normalOtRef.current.getOverTimeReimbursement() : ''
        let weekend = otTypes.includes("weekend") ? weekendOtRef.current.getOverTimeReimbursement() : ''
        let otType = { normal, weekend }
        let data = {
            department_name: departmentName,
            shift_id: shift,
            department_id,
            otType
        }
        // console.log(data);
        handleAuth.mutate(data)
    }
    const reset = () => {
        setDepartmentName('')
        setShift('')
    }
    const handleCloseModal = () => {
        reset()
        onClose()
        setIsSuccess(false)
        setError(false)
    }
    const handleChangeOtDefine = (e) => {
        let value = e.target.id
        let checked = e.target.checked
        if (checked) {
            setOtTypes(prev => {
                return [...prev, value]
            })
        } else {
            let filter = otTypes.filter(x => x !== value)
            setOtTypes(filter)
        }
    }
    return (
        <Modal open={open} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
            <div className='w-[40%] bg-white absolute left-1/2 -translate-x-1/2 top-[1%] max-h-[30rem] overflow-auto'>
                <div className='document-wrapper px-4 divide-y'>
                    <div className="document-head py-4">
                        <div className='flex items-center justify-between'>
                            <h3 className=' text-gray-700 text-3xl font-semibold'>Edit Department</h3>
                            <div className=' w-6 h-6 cursor-pointer' onClick={handleCloseModal}>
                                <XIcon className=' text-black w-full h-full' />
                            </div>
                        </div>
                    </div>
                    <div className="document-body py-4 space-y-4">
                        <div className='grid grid-cols-2 gap-x-4 border-b border-gray-200 pb-4'>
                            <div className="">
                                <label htmlFor="first_name" className='label'>Department Name:<span className='mandatory'>*</span></label>
                                <input type="text" name='first_name' required={true} value={departmentName} onChange={(e) => setDepartmentName(e.target.value)} className='input' placeholder='Enter department name ' />
                            </div>
                            <div className="">
                                <label htmlFor="salary" className='label'>Select Shift:<span className='mandatory'>*</span></label>
                                <SelectSearch
                                    onChange={handleChangeDepartment}
                                    search
                                    value={shift}
                                    name="department"
                                    placeholder="Select department"
                                    getOptions={handleSetCustomOption}
                                />
                            </div>
                        </div>
                        <div>
                            <h2 className='font-semibold'>Define Overtime For The Department</h2>
                            <div className='flex items-center space-x-4'>
                                <div className='space-x-2 mt-4'>
                                    <input type="checkbox" checked={otTypes.includes("normal")} id="normal" onChange={handleChangeOtDefine} name='otDefine' />
                                    <label htmlFor="normal" className='label text-lg' >Normal OT</label>
                                </div>
                                <div className='space-x-2 mt-4'>
                                    <input type="checkbox" id="weekend" checked={otTypes.includes("weekend")} onChange={handleChangeOtDefine} name='otDefine' />
                                    <label htmlFor="weekend" className='label text-lg'>Weekend OT</label>
                                </div>
                            </div>
                        </div>
                        <div className=' divide-y divide-gray-200 space-y-5'>
                            {otTypes.includes("normal") &&
                                <div>
                                    <h2 className='font-semibold'>Normal OT Definition</h2>
                                    <OvertimeReimbursement data={data?.otType?.['normal']} ref={normalOtRef} name={'normalOtDefinition'} />
                                </div>
                            }
                            {otTypes.includes("weekend") &&
                                <div className=' pt-4'>
                                    <h2 className='font-semibold'>Weekend OT Definition</h2>
                                    <OvertimeReimbursement data={data?.otType?.['weekend']} ref={weekendOtRef} name={'weekendOtDefinition'} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="document-foot space-x-4 py-4">
                        <div className="grid grid-cols-2 gap-3">
                            <button className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white' onClick={handleSaveDepartment}>
                                <div className='flex items-center justify-center space-x-2'>
                                    <span>
                                        Update Department
                                    </span>
                                    {handleAuth.isLoading && <Loader />}
                                </div>
                            </button>
                            <button className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                    <ToastMessage open={isError} message={'Something Went Wrong'} variant={'error'} />
                    <ToastMessage open={isSuccess} message={'Department Updated Successfully'} variant={'success'} />
                </div>
            </div>
        </Modal>
    )
}

export default EditDepartment