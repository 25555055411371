import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

const IS_REQUIRED = true
const BankDetailsForm = forwardRef(({ bankDetailData }, ref) => {
  const bankDetailInitialState = {
    bank_name: '',
    ifsc_code: '',
    account_number: ''
  }
  useImperativeHandle(ref, (() => ({
    getBankDetailData: () => {
      return bankDetailForm
    }
  })))
  const [bankDetailForm, setBankDetailForm] = useState(bankDetailInitialState)
  const handleSetEmployeDetail = (e) => {
    setBankDetailForm((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }
  useEffect(() => {
    if (bankDetailData) {
        Object.keys(bankDetailInitialState).forEach((key) => {
          setBankDetailForm((prev) => {
                return {
                    ...prev,
                    [key]: bankDetailData[key]
                }
            })
        })
    }
}, [bankDetailData])
  return (
    <div className=' space-y-3'>
      <h1 className='mt-4 text-2xl font-semibold'>Bank Details</h1><div></div>
      <div className='grid grid-cols-3 gap-x-4'>
        <div className="">
          <label htmlFor="bank_name" className='label'>Bank Name: <span className='mandatory'>*</span></label>
          <input type="text" name='bank_name' className='input' required={IS_REQUIRED} value={bankDetailForm.bank_name} onChange={handleSetEmployeDetail} placeholder='Bank name' />
        </div>
        <div className="">
          <label htmlFor="ifsc_code" className='label'>IFSC Code:<span className='mandatory'>*</span></label>
          <input type="text" name='ifsc_code' className='input' required={IS_REQUIRED} value={bankDetailForm.ifsc_code} onChange={handleSetEmployeDetail} placeholder='IFSC Code' />
        </div>
        <div className="">
          <label htmlFor="account_number" className='label'>Account Number:<span className='mandatory'>*</span></label>
          <input type="tel" name='account_number' className='input' required={IS_REQUIRED} value={bankDetailForm.account_number} onChange={handleSetEmployeDetail} placeholder='Account number' />
        </div>
      </div>
    </div>
  )
})

export default BankDetailsForm