import { XIcon, ArrowLeftIcon } from '@heroicons/react/solid'
import { Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getSalaryList } from '../../../api/employeeDetails'
import Layout from '../../../components/layout/Layout'
import DatePicker from "react-datepicker";
import spinner from '../../../assets/images/spinner.gif';
// import Calendar from '../../components/Dates/Calendar'
import { downloadReport } from '../../../api'
// import { getAttendanceData } from '../../api/leaveApplicationForm'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Pagination from '../../../components/pagination';
import Salary from '..'
import CompanyPayroll from '../CompanyPayroll'
import BulkSalaryModal from '../../../components/salary/BulkSalaryModal'

const getDateMonthString = (date) => {
    let tempDate = new Date(date)
    let month = tempDate.getMonth() + 1
    return tempDate.getFullYear() + "-" + month
}

function BaseSalary() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    let initialData = {
        month_and_year: searchParams.get('month_and_year') ? new Date(searchParams.get('month_and_year')) : new Date(),
        prev_month_and_year: new Date(),
        page_number: searchParams.get('page_number') || 0,
        limit: searchParams.get('limit') || 20,
        search_query: searchParams.get('search_query') || '',
    }

    const [filterData, setFilterData] = useState(initialData)
    const [isFetching, setIsFetching] = useState(false)
    const [salaryList, setSalaryList] = useState([])
    const [count,setCount] = useState('')
    const [companyPayout,setCompanyPayout] = useState([])

    const handleDate = (value, name) => {
        let data = {
            ...filterData,
            [name]: value,
        }
        setFilterData(data)
        handleCalculateSalary(data)
        setSearchParams(data)
    }
    const handleCalculateSalary = (data) => {
        const temp = {
            ...data,
            month_year: filterData.month_and_year,
            page_number: filterData.page_number,
            search_query: filterData.search_query,
            limit: filterData.limit
        }
        setIsFetching(true)
        getSalaryList(temp)
            .then((res) => {
                if (res.status === 200) {
                    setSalaryList(res?.data.data)
                    setCompanyPayout(res?.data.company_payout)
                    setCount(res?.data.total_count)
                    setIsFetching(false)
                    scrollToTop()
                }
            })
            .catch(err => {
                alert('Something went wrong!')
                setIsFetching(false)
            })
    }

    const handleDownloadReport = () => {
        const temp = {
            ...filterData,
            month_year: getDateMonthString(filterData['month_and_year'])
        }
        downloadReport(temp)
            .then((res) => {
                // console.log(res)
                // create file link in browser's memory
                const href = URL.createObjectURL(res.data);
                const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                const d = new Date();
                let fileName = `Salary_Report_${months[d.getMonth()]}_${d.getFullYear()}.xlsx`
                // create "a" HTLM element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(() => { })
    }

    useEffect(() => {
        handleCalculateSalary(filterData)
    }, [filterData.page_number,filterData.search_query])

    const handleNavigateToEmployeeDetail = (id, username) => {
        // navigate(`/employee-salary-details/${id}/${username}`)
        navigate(`/employee-salary-details/${id}`)
    }

    const handleSearch = (e) => {
        let value = e.target.value
        let name = e.target.name
        let data = {
            ...filterData,
            [name]: value
        }
        setFilterData(data)
        setSearchParams(data)
    }
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Smooth scrolling animation
        });
      };
    const handlePageClick = (val) => { 
        let data = {
            ...filterData,
            page_number: val.selected
        }
        setFilterData(data)
        setSearchParams(data)
    }
    const [isImportModalOpen,setIsModalOpen] = useState(false)
    return (
        <Salary>
            {isFetching && <div className=' w-full h-full fixed top-0 left-0 flex items-center justify-center bg-[#ffffffbf] z-50'>
                <img src={spinner} alt="spinner" className='w-20 h-20' />
            </div>}
            {/* <Layout> */}
                <div className=' mt-14 mb-20'>
                    <div className=''>
                        <form className='flex items-end justify-between px-5'>
                            <div className='flex items-end space-x-4'>
                                <div>
                                    <input type="search" placeholder='Search By Name...' value={filterData?.search_query} name='search_query' className='input' onChange={handleSearch} />
                                </div>
                                <div className='min-w-[10rem]  basis-[35%]'>
                                    <label className=' roboto label'>Select Month/Year</label>
                                    <DatePicker required name='month_and_year' autoComplete='false' className='input' dateFormat="MM/yyyy" showMonthYearPicker selected={filterData?.month_and_year} onChange={(value) => handleDate(value, 'month_and_year')} />
                                </div>
                            </div>
                            <div className='px-4'>
                                <div className='flex space-x-4 items-end justify-between'>
                                    {salaryList?.length > 0 &&
                                        <div className=''>
                                            <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2 text-xs border border-[color:var(--color1)]">Download Report</button>
                                        </div>}
                                        {/* onClick={() => setIsModalOpen(true)}  */}
                                    <button type="button"  onClick={() => setIsModalOpen(true)} className=" border border-gray-600 rounded px-4 py-2 text-xs">Import Salary</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <CompanyPayroll data={companyPayout} filterData={filterData}/>
                    {salaryList?.length > 0 ?
                        <>
                            <div className="max-w-full overflow-x-auto px-4 mt-8">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className=" bg-[color:var(--color1)] text-center">
                                            <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th>
                                            <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Name</th>
                                            <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">User Type</th>
                                            <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salaryList?.map((data, idx) => {
                                            const { employee_name, status, user_type, id } = data
                                            return <tr key={idx} className='bg-gray-200 hover:bg-gray-100 cursor-pointer' onClick={() => handleNavigateToEmployeeDetail(id, employee_name)}>
                                                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">
                                                    {(filterData.limit * (parseInt(filterData.page_number) + 1)) - (filterData.limit - (idx + 1))}
                                                </td>
                                                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8] capitalize">{user_type}</td>
                                                {

                                                }
                                                <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                                                    {
                                                        status === "left" ? (
                                                            <div className='flex items-center justify-center'>
                                                                Left
                                                            </div>
                                                        ) : status === 'joining_date_diff' && (
                                                            <div className='flex items-center justify-center'>
                                                                Joining Date Diff
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        status === "not_generated" ? (
                                                            <Link
                                                            onClick={(e) => e.stopPropagation()}
                                                                to={'/salary/logs?id=' + id + "&date=" + filterData['month_and_year'] + "&prev_date=" + filterData['prev_month_and_year'] + "&status=not_generated" + "&emp=" + user_type}
                                                                className='px-4 py-2 rounded bg-[color:var(--color1)] text-white'
                                                            >
                                                                Generate
                                                            </Link>
                                                        ) : status === 'generated' && <>
                                                            <Link
                                                            onClick={(e) => e.stopPropagation()}
                                                                to={'/salary/logs?id=' + id + "&date=" + filterData['month_and_year'] + "&prev_date=" + filterData['prev_month_and_year'] + "&status=generated" + "&emp=" + user_type}
                                                                className='px-4 py-2 rounded bg-[color:var(--color1)] text-white'
                                                            >
                                                                View
                                                            </Link>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='px-5 mt-5'>
                                <Pagination
                                    currentPage={+filterData.page_number}
                                    lengthofItems={count}
                                    limit={filterData.limit}
                                    onPageChange={handlePageClick}
                                />
                            </div>
                        </>
                        : 
                        <p className=' text-center font-semibold text-lg mt-10'>No Employee Found</p>
                    }
                </div>
            {/* </Layout> */}
            <BulkSalaryModal open={isImportModalOpen} handleClose={() => setIsModalOpen(false)}/>
        </Salary>
    )
}

export default BaseSalary