import React, { useState } from "react";
import { EyeIcon } from "@heroicons/react/solid";
import { Link, useSearchParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import useGet from "../../hooks/read/useGet";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "../../components/pagination";
import PrimaryLoader from "../../components/common/PrimaryLoader";

const WebCheckIns = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };
  const initialEmployeeState = {
    page_number: searchParams.get("page_number") || 0,
    limit: searchParams.get("limit") || 10,
    search_query: searchParams.get("search_query") || "",
  };
  const {
    list: employeeList,
    paramsObject,
    setGetListParams,
  } = useGet({
    url: "web_check_in_employee_list",
    initialData: initialEmployeeState,
    onSuccess: () => {
      scrollToTop();
      // toast.success('Employees Fetched Successfully')
    },
    onError: () => {
      toast.error(
        "There Was An Error While Fetching The Data Please Try Again Later"
      );
    },
  });
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      page_number: val.selected,
    };
    setGetListParams(data);
    setSearchParams(data);
  };
  const handleSearch = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {
      ...paramsObject,
      [name]: value,
    };
    setGetListParams(data);
    setSearchParams(data);
  };
  return (
    <Layout>
    <>
      <Toaster />
      <>
        <div className="flex items-center justify-between px-4 mb-10">
          <div className=" w-[400px]">
            <input
              type="search"
              placeholder="Search By Name..."
              name="search_query"
              className="input"
              value={paramsObject.search_query}
              onChange={handleSearch}
            />
          </div>
        </div>
      </>
      {employeeList.isLoading ? (
        <div className=" flex items-center justify-center mt-10">
          {" "}
          <PrimaryLoader />
        </div>
      ) : (
        <>
          {employeeList?.data?.data?.length <= 0 ? (
            <div className="text-center">
              <p className=" text-gray-700 text-3xl">No Users To Show</p>
            </div>
          ) : (
            <div class="max-w-full overflow-x-auto px-4">
              <table class="table-auto w-full">
                <thead>
                  <tr class=" bg-[color:var(--color1)] text-center">
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                      Name
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Email
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      User Type
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Attendance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeList?.data?.data?.map((data, index) => {
                    const { employee_name, email, id, type_of_employee } = data;
                    return (
                      <tr key={index}>
                        <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                          {employee_name}
                        </td>
                        <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          {email}
                        </td>
                        <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">
                          {type_of_employee}
                        </td>
                        <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          <div className="flex items-center justify-center w-full">
                            <Link
                              to={`/web-check-ins/${id}`}
                              className="bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center"
                            >
                              <EyeIcon className="w-6 h-6" />
                              <span>View</span>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <div className="px-5 mt-5 mb-10">
            <Pagination
              currentPage={+paramsObject.page_number}
              lengthofItems={employeeList.data?.total_count}
              limit={+paramsObject.limit}
              onPageChange={handlePageClick}
            />
          </div>
        </>
      )}
    </>
        </Layout>
  );
};

export default WebCheckIns;
