import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { PencilAltIcon, XIcon } from '@heroicons/react/solid'
import AddDepartment from './AddDepartmant'
import EditDepartment from './EditDepartment'
import useReadWithGet from '../../hooks/read/useReadWithGetMethod'

function Department() {
  let initialShiftData = {
    limit: 50,
    searchQuery: '',
  }
  let initialDepartmentState = {
    limit: 50,
    searchQuery: '',
  }
  const { list } = useReadWithGet({
    url: "shift_listing",
    initialData: initialShiftData,
  })
  const department = useReadWithGet({
    url: "department_listing",
    initialData: initialDepartmentState,
  })
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false)
  const [openDepartmentEditModal, setOpenDepartmentEditModal] = useState(false)
  const [departmentId, setDepartmentId] = useState(null)
  const [singleDepartmentData, setSingleDepartmentData] = useState(null)
  const handleEditDepartment = (id) => {
    setDepartmentId(id)
    let data = department.list?.data.data.find(x => x.id === id)
    setSingleDepartmentData(data)
    setOpenDepartmentEditModal(true)
  }
  return (
    <Layout>
      <div className='px-4 text-end'>
        <button type='button' onClick={() => setOpenDepartmentModal(true)} className=" bg-[color:var(--color1)] text-white rounded px-4 py-4">Add Department</button>
      </div>
      <div class="max-w-full overflow-x-auto px-4 mt-8 mb-10">
        <table class="table-auto w-full">
          <thead>
            <tr class=" bg-[color:var(--color1)] text-center">
              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.no</th>
              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Department Name</th>
              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4border-r border-transparent">Modify</th>
            </tr>
          </thead>
          <tbody>
            {department.list.data?.data.map((departmentList, departmentIdx) => {
              const { name, id } = departmentList
              return <tr>
                <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{departmentIdx + 1}</td>
                <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{name}</td>
                <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                  <div className='text-center mx-auto w-6 h-6 cursor-pointer' onClick={() => handleEditDepartment(id)}>
                    <PencilAltIcon className=' text-gray-600 w-full h-full hover:text-blue-600' />
                  </div>
                </td>
              </tr>
            })}

          </tbody>
        </table>
      </div>
      <AddDepartment options={list?.data?.data} open={openDepartmentModal} onClose={() => setOpenDepartmentModal(false)} />
      {openDepartmentEditModal && <EditDepartment options={list?.data?.data} department_id={departmentId} data={singleDepartmentData} open={openDepartmentEditModal} onClose={() => setOpenDepartmentEditModal(false)} />}
    </Layout>
  )
}

export default Department