import { useState } from "react"
import { useQuery } from "@tanstack/react-query";
import axios from "../api/newAxios";
import Cookies from "universal-cookie";
import { TOKEN } from "../config";
const cookies = new Cookies();

const useRead = (data) => {
  const { url, initialData, onSuccess, onError, onSettled } = data
  const [paramsObject, setGetListParams] = useState(initialData)
  const list = useQuery([url, paramsObject], () => getUsers(paramsObject), {
    refetchOnWindowFocus: false,
    onSuccess(data) {
      onSuccess?.(data)
    },
    onError(err) {
      onError?.(err)
    },
    onSettled() {
      onSettled?.()
    }
  })
  const getUsers = async (paramsObject) => {
    let token = cookies.get(TOKEN);
    let fd = new FormData()
    for (var key in paramsObject) {
      fd.append(key, paramsObject[key]);
    }
    const response = await axios({
      method: "POST",
      data: fd,
      url: `/${url}`,
      headers: { Authorization: token ? `Bearer ${token}` : '', }
    })
    return response.data
  }

  return { list, paramsObject, setGetListParams }
}

export default useRead