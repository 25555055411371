import React, { useState } from 'react'
import Salary from '..'
import DatePicker from "react-datepicker";
import { useNavigate, useSearchParams } from 'react-router-dom';
import useRead from '../../../hooks/useRead';
import SelectSearch from 'react-select-search';
import useReadWithGet from '../../../hooks/read/useReadWithGetMethod';
import useDownloadFile from '../../../hooks/useDownloadFile';

const Loan = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    let initialData = {
        month_and_year: searchParams.get('month_and_year') ? new Date(searchParams.get('month_and_year')) : new Date(),
        prev_month_and_year: new Date(),
        page_number: searchParams.get('page_number') || 0,
        limit: searchParams.get('limit') || 50,
        search_query: searchParams.get('search_query') || '',
        department: ''
    }

    const { list, paramsObject, setGetListParams } = useRead({
        url: '',
        initialData
    })
    const [departOption, setDepartOption] = useState([])
    useReadWithGet({
        url: "department_listing",
        initialData: { limit: 50, searchQuery: '' },
        onSuccess: (res) => {
            let data = res.data.map(({ id, name }) => ({ value: id, name: name }))
            setDepartOption(data);
        }
    })
    const { } = useDownloadFile({
        url: '',
        filename: ''
    })
    const handleSearch = () => { }
    const handleDate = () => { }
    const handleChangeDepartment = () => { }
    const handleDownloadReport = () => { }
    return (
        <Salary>
            <div className=' mt-14 mb-20'>
                <div className=''>
                    <form className='flex items-end justify-between px-5'>
                        <div className='flex items-end space-x-4'>
                            <div>
                                <input type="text" placeholder='Search By Name...' name='search_query' className='input' onChange={handleSearch} />
                            </div>
                            <div className='min-w-[10rem]  basis-[35%]'>
                                <label className=' roboto label'>Select Month/Year</label>
                                <DatePicker required name='month_and_year' autoComplete='false' className='input' dateFormat="MM/yyyy" showMonthYearPicker selected={paramsObject?.month_and_year} onChange={(value) => handleDate(value, 'month_and_year')} />
                            </div>
                            <div className="">
                                <label htmlFor="salary" className='label'>Select Department:<span className='mandatory'>*</span></label>
                                <SelectSearch
                                    onChange={handleChangeDepartment}
                                    search
                                    options={departOption}
                                    value={paramsObject.department}
                                    name="department"
                                    placeholder="Select department" />
                            </div>
                        </div>
                        <div className='px-4'>
                            <div className='flex space-x-4 items-end justify-between'>
                                {/* {salaryList?.length > 0 &&
                                    <div className=''>
                                        <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Download Report</button>
                                    </div>} */}
                                <div className=''>
                                    <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Download Report</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Salary>
    )
}

export default Loan