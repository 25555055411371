import Layout from "../../components/layout/Layout"
import DatePicker from "react-datepicker";
import { useState } from "react";
import { useParams, useSearchParams,useNavigate } from 'react-router-dom'
import { getAttendanceList,processAttendanceList, saveUpdateAttendance } from "../../api/attendance";
import { ArrowLeftIcon, EyeIcon, PencilAltIcon, XIcon } from "@heroicons/react/solid";
import { Modal } from "@mui/material";
import spinner from '../../assets/images/spinner.gif'
import Moment from "react-moment";
import ViewAttendanceDetail from "./ViewAttendanceDetail";
import EditAttendance from "./EditAttendance";


const IS_REQUIRED = true
function ViewAttendance() {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const employeeName = searchParams.get('employee');
    const { id } = useParams()
    let initialData = {
        month_and_year: new Date(),
        user_id: id
    }
    let initialAttendanceData = {
        remarks:'',
        out_date: '',
        in_date: '',
        in_time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" }),
        out_time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" }),
        user_id: id,
        id: '',
        present_status: '',
        month_and_year:''
    }
    const [filterData, setFilterData] = useState(initialData)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isFetching, setIsFetching] = useState(false)
    const [isDataFound, setDataFound] = useState(true)
    const [attendanceList, setAttendanceList] = useState([])
    const [addAttendanceModal, setAddAttendanceModal] = useState(false)
    const [attendanceFormData, setAttendanceFormData] = useState(initialAttendanceData)
    const [isSaving, setIsSaving] = useState(false)
    const [attendanceEditModal, setEditAttendanceModal] = useState(false)
    const [singleAttendanceData, setSingleAttendanceData] = useState({})
    const [attendanceId, setAttendanceData] = useState('')
    const [isDateCalculated, setIsDateCalculated] = useState(false)
    const handleDate = (value, name) => {
        let data = {
            ...filterData,
            [name]: value,
        }
        setFilterData(data)
    }
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    const handleConfirmProcessAttendance = async () => {
      setIsModalOpen(false); // Close the modal
  
      let initialData = {
        month_and_year: filterData.month_and_year,
        user_id: id
      };
  
      let data = {
        ...initialData,
      };
  
      setFilterData(data);
  
      try {
        const response = await processAttendanceList(data);
        console.log('Response:', response);
        // Handle the response as needed
      } catch (error) {
        console.error('Error processing attendance:', error);
        // Handle the error as needed
      }
    };
    const handleCalculateSalary = () => {
        console.log(new Date().toLocaleTimeString())
        setDataFound(true)
        setIsFetching(true)
        getAttendanceList(filterData)
       

            .then((res) => {
                if (res.status === 200) {
                    setAttendanceList(res.data)
                    if (res.data.length <= 0) {
                        setDataFound(false)
                    }
                    setIsFetching(false)
                    setIsDateCalculated(true)
                }
            })
            .catch(err => {
                alert('Something went wrong!')
                setIsFetching(false)
            })
    }

    const handleHolidayFormInputs = (value) => {
        let data;
        if (value.isDateTime) {
            data = {
                ...attendanceFormData,
                [value.name]: value.value
            }
        } else if (value.name === 'in_date' || value.name === 'out_date') {
            data = {
                ...attendanceFormData,
                [value.name]: value.value
            }
        }
        else {
            let val = value.target.value
            data = {
                ...attendanceFormData,
                [value.target.name]: val
            }
        }
        setAttendanceFormData(data)
    }


    const handleCancelForm = () => {
        setAddAttendanceModal(false)
        setEditAttendanceModal(false)
        setAttendanceFormData(initialAttendanceData)
        setAttendanceData('')
    }
    const handleAddAttendance = () => {
        setAddAttendanceModal(true)
    }
    const handleSaveAttendance = (e, action) => {
        setIsSaving(true)
        e.preventDefault()
        // console.log(attendanceFormData);
        saveUpdateAttendance(attendanceFormData, action, attendanceId)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setIsSaving(false)
                    setAddAttendanceModal(false)
                    // handleCancelForm()
                    handleCalculateSalary()
                    setAttendanceFormData(initialAttendanceData)
                }
            })
            .catch((err) => {
                alert('Something went wrong please try again later!')
                setIsSaving(false)
            })
    }

    const handleGetSingleData = (id) => {
        setAttendanceData(id)
        setEditAttendanceModal(true)
        const data = attendanceList.find(data => data.id === id)
        setSingleAttendanceData(data)
        setAttendanceFormData(data)
    }
    function tConvert(time) {
        if (!time || time === undefined) {
            return time = '---'
        }
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
            time.splice(3, 1)
        }
        return time.join(''); // return adjusted time or original string
    }
    const [isViewAttendanceDrawerShow, setIsViewAttendanceDrawerShow] = useState(false)
    const handleOpenAttendanceDrawer = (id) => {
        setIsViewAttendanceDrawerShow(true)
        setAttendanceData(id)
    }
    return (
        <Layout>
            <div className='px-4 mb-20'>
                <div className="pb-4 flex items-center space-x-3">
                    <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={() => navigate(-1)} />
                    <h1 className="font-semibold text-lg">Attendance For <span className=" italic bg-purple-200 text-purple-700 px-5 py-1 rounded">"{employeeName}"</span></h1>
                </div>
                <form>
                    <div className='flex space-x-4 items-end'>
                        <div className="flex space-x-4 items-end flex-1">
                            <div className=' basis-[25%]'>
                                <label className=' roboto  label'>Select Month/Year</label>
                                <DatePicker required name='month_and_year' autoComplete='false' className='input' dateFormat="MM/yyyy" showMonthYearPicker selected={filterData?.month_and_year} onChange={(value) => handleDate(value, 'month_and_year')} />
                            </div>
                            <div>
                                <button type="button" onClick={handleCalculateSalary} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2 mt-12">Fetch Attendance</button>
                            </div>
                            <div>
                            <button
        type="button"
        onClick={handleOpenModal}
        className="bg-[color:var(--color1)] text-white rounded px-4 py-2 mt-12"
      >
        Reprocess Attendance
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4">
            <p>Do you want to reprocess attendance?</p>
            <div className="mt-4">
              <button
                className="bg-green-500 text-white rounded px-4 py-2 mr-2"
                onClick={handleConfirmProcessAttendance}
              >
                Yes
              </button>
              <button
                className="bg-red-500 text-white rounded px-4 py-2"
                onClick={handleCloseModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
                            </div>
                        </div>
                        {isDateCalculated &&
                            <div>
                                <button type="button" onClick={handleAddAttendance} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2 mt-12">Add Attendance</button>
                            </div>}
                    </div>
                </form>
                {isFetching ? <div className=' w-12 h-12 mx-auto mt-12'><img src={spinner} alt="spinner" className='w-full h-full' /></div> :
                    <div className="max-w-full overflow-x-auto mt-8">
                        <table className="table-auto w-full">
                            <thead>
                                {/* w-1/6 min-w-[160px] */}
                                <tr className=" bg-[color:var(--color1)] text-center">
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">In-Date</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">In-Time</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Out-Date</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Out-Time</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Attendance</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Action</th>
                                </tr>
                            </thead>
                            {!isDataFound ? <tr><td colSpan={6}><div className="text-center py-5 text-gray-700 text-3xl font-semibold">Opps!!! No Data found</div></td></tr> : <tbody>
                                {attendanceList?.map((data, idx) => {
                                    const { in_date, in_time,out_date, out_time, id, present_status } = data
                                    return <tr key={idx}>
                                        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{idx + 1}</td>
                                        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                            <Moment unix format="L" date={in_date}/>
                                        </td>
                                        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                            {tConvert(in_time)}
                                        </td>
                                        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                            <Moment unix format="L" date={out_date}/>
                                        </td>
                                        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                            {tConvert(out_time)}
                                        </td>
                                        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                            {present_status === 'P' ? <span className="text-sm text-green-700 bg-green-200 px-4 py-1 rounded-full">Present</span> : <span className="text-sm text-red-700 bg-red-200 px-4 py-1 rounded-full">Absent</span>}
                                        </td>
                                        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                            <div className="flex items-center justify-center space-x-4">
                                                <div className="cursor-pointer w-6" onClick={() => handleOpenAttendanceDrawer(id)}>
                                                    <EyeIcon className=' text-gray-600 w-full  hover:text-blue-600' />
                                                </div>
                                                <div className="cursor-pointer w-6" onClick={() => handleGetSingleData(id)}>
                                                    <PencilAltIcon className=' text-gray-600 w-full  hover:text-blue-600' />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                            </tbody>}
                        </table>
                    </div>}
            </div>
            <Modal open={addAttendanceModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                    <div className='document-wrapper px-4 divide-y'>
                        <div className="document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h3 className=' text-gray-700 text-3xl font-semibold'>Add Attendance</h3>
                                <div className=' w-6 h-6 cursor-pointer' onClick={handleCancelForm}>
                                    <XIcon className=' text-black w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className="document-body max-h-96 overflow-auto">
                            <form method="post " className='divide-y' onSubmit={(e) => handleSaveAttendance(e, 'Save')}>
                                <div className="grid grid-cols-2 gap-4 py-4">
                                    <div className="">
                                        <label htmlFor="holiday_name" className='label'>In-Date:<span className='mandatory'>*</span></label>
                                        <DatePicker selected={attendanceFormData.in_date} name='date' onChange={(value) => handleHolidayFormInputs({ value, name: 'in_date' })} className='input' dateFormat="dd/MM/yyyy" />
                                    </div>
                                    <div className="">
                                        <label htmlFor="holiday_name" className='label'>Out-Date:<span className='mandatory'>*</span></label>
                                        <DatePicker selected={attendanceFormData.out_date} name='date' onChange={(value) => handleHolidayFormInputs({ value, name: 'out_date' })} className='input' dateFormat="dd/MM/yyyy" />
                                    </div>
                                    <div className="">
                                        <label htmlFor="in_time" className='label'>In Time:<span className='mandatory'>*</span></label>
                                        <input type="time" name='in_time' value={attendanceFormData.in_time} className='input' onChange={(e) => handleHolidayFormInputs(e)} />
                                    </div>
                                    <div className="">
                                        <label htmlFor="out_time" className='label'>Out Time:<span className='mandatory'>*</span></label>
                                        <input type="time" name='out_time' value={attendanceFormData.out_time} className='input' onChange={(e) => handleHolidayFormInputs(e)} />
                                    </div>
                                    <div className="">
                                        <label htmlFor="status" className='label'>Status:<span className='mandatory'>*</span></label>
                                        <select className='input' name="present_status" required={IS_REQUIRED} value={attendanceFormData.present_status} onChange={(e) => handleHolidayFormInputs(e)}>
                                            <option value="">--Select Attendance--</option>
                                            <option value="P">Present</option>
                                            <option value="A">Absent</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="document-foot space-x-4 py-4">
                                    {isSaving ? <div className=' w-8 h-8 mx-auto'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className="text-center space-x-4">
                                        <button className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white'>Save</button>
                                        <button type='button' className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={handleCancelForm}>Cancel</button>
                                    </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* <Modal open={attendanceEditModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                    <div className='document-wrapper px-4 divide-y'>
                        <div className="document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h3 className=' text-gray-700 text-3xl font-semibold'>Edit Attendance</h3>
                                <div className=' w-6 h-6 cursor-pointer' onClick={handleCancelForm}>
                                    <XIcon className=' text-black w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className="document-body max-h-96 overflow-auto">
                            <form method="post " className='divide-y' onSubmit={(e) => handleSaveAttendance(e, 'Update')}>
                                <div className="grid grid-cols-2 gap-4 py-4">
                                    <div className="">
                                        <label htmlFor="holiday_name" className='label'>Date:<span className='mandatory'></span></label>
                                        <input type="date" name='date' readOnly disabled value={attendanceFormData.date} className='input' onChange={(e) => handleHolidayFormInputs(e)} />
                                    </div>
                                    <div className="">
                                        <label htmlFor="in_time" className='label'>In Time:<span className='mandatory'>*</span></label>
                                        <input type="time" name='in_time' value={attendanceFormData.in_time} className='input' onChange={(e) => handleHolidayFormInputs(e)} />
                                    </div>
                                    <div className="">
                                        <label htmlFor="out_time" className='label'>Out Time:<span className='mandatory'>*</span></label>
                                        <input type="time" name='out_time' value={attendanceFormData.out_time} className='input' onChange={(e) => handleHolidayFormInputs(e)} />
                                    </div>
                                    <div className="">
                                        <label htmlFor="shift" className='label'>Shift:<span className='mandatory'>*</span></label>
                                        <input type="text" name='shift' className='input' required={IS_REQUIRED} value={attendanceFormData.shift} onChange={(e) => handleHolidayFormInputs(e)} placeholder='Shift' />
                                    </div>
                                    <div className="">
                                        <label htmlFor="status" className='label'>Status:<span className='mandatory'>*</span></label>
                                        <select className='input' name="status" required={IS_REQUIRED} value={attendanceFormData.status} onChange={(e) => handleHolidayFormInputs(e)}>
                                            <option value="">--Select Attendance--</option>
                                            <option value="P">Present</option>
                                            <option value="A">Absent</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="document-foot space-x-4 py-4">
                                    {isSaving ? <div className=' w-8 h-8 mx-auto'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className="text-center space-x-4">
                                        <button className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white'>Update</button>
                                        <button type='button' className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={handleCancelForm}>Cancel</button>
                                    </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal> */}
            <ViewAttendanceDetail id={attendanceId} onClose={() => setIsViewAttendanceDrawerShow(false)} open={isViewAttendanceDrawerShow} />
            {attendanceEditModal && <EditAttendance id={attendanceId} onClose={handleCancelForm} open={attendanceEditModal}/>}
        </Layout>
    )
}

export default ViewAttendance

