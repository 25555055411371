import React from 'react'
import Layout from '../../components/layout/Layout'
import Moment from 'react-moment'
import { useGeolocated } from "react-geolocated";
import usePut from '../../hooks/usePut';
import PrimaryLoader from '../../components/common/PrimaryLoader';
import { useState } from 'react';

const User = () => {
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
    const [type,setType] = useState('')
    const handlePunchAttendance = usePut({
        url: 'user_check_in',
        onSuccess: (res) => {
            alert(`${type} Success`)
        },
        onError: (err) => {
            alert('Error Ocurred While Punching, Please Contact Admin')
        }
    })
    const handleCheckIn = (type,action) => {
        setType(action)
        let data = {
            type,
            lat: coords?.latitude,
            long: coords.longitude
        }
        handlePunchAttendance.handleAuth.mutate(data)
    }
    return <Layout>
        {handlePunchAttendance.handleAuth.isLoading &&
            <div className=' fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-[#ffffffc7]'>
                <PrimaryLoader />
            </div>}
        {!isGeolocationAvailable
            ? <div className='text-center text-red-600 font-semibold mt-10'>Your browser does not support Geolocation</div>
            : !isGeolocationEnabled ? <div className='text-center text-red-600 font-semibold mt-10'>Location is not enabled</div>
                : coords ? <div className='mx-5 shadow-xl rounded-lg mt-16'>
                    <div className='px-4 py-8'>
                        <h2 className=' text-lg text-center font-semibold'>Web Check-in for:
                            <span className='ml-1 text-lg'>
                                <Moment date={new Date()} format='ll' />
                            </span>
                        </h2>
                        <div className='text-center space-x-4 mt-4'>
                            <button className=' px-4 rounded bg-green-100 text-green-600 font-semibold py-2' onClick={() => handleCheckIn('punch_in','Punch in')}>
                                Check In
                            </button>
                            <button className=' px-4 rounded bg-yellow-100 text-yellow-600 font-semibold py-2' onClick={() => handleCheckIn('punch_out', 'Punch out')}>
                                Check Out
                            </button>
                        </div>
                    </div>
                </div>
                    : <div className='text-center text-red-600 font-semibold mt-10'>Getting the location data&hellip; </div>
        }
    </Layout>
}

export default User
