import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/solid";
import useReadWithGet from "../../hooks/read/useReadWithGetMethod";
import { useParams } from "react-router-dom";
import { formatTime } from "../../utility";
import Moment from "react-moment";
import usePut from "../../hooks/usePut";
import toast, { Toaster } from "react-hot-toast";
import PrimaryLoader from "../../components/common/PrimaryLoader";
import Pagination from "../../components/pagination";
import moment from "moment";

const HibernationDrawer = ({ open, onClose, mode }) => {
  const [isEnding, setisEnding] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const [showStartDate, setShowStartDate] = useState(false);
  const { id, username } = useParams();
  const { list } = useReadWithGet({
    url: "shift_listing",
    initialData: {
      limit: 50,
      searchQuery: "",
    },
  });
  const {
    list: userHibernateListing,
    paramsObject,
    setGetListParams,
    refetch: refetchUserHibernateListing,
  } = useReadWithGet({
    url: "fetch_single_user_hibernation_data",
    initialData: {
      limit: 20,
      searchQuery: "",
      user_id: id,
      page_number: 0,
    },
    onSuccess: (res) => {
      console.log(res);
      setShowStartDate(res?.current_active)
    }
  });
  const { handleAuth: submitHibernation } = usePut({
    url: "add_update_user_to_hibernation",
    refreshUrl: "fetch_single_user_hibernation_data",
    onSuccess: () => {
      toast.success("Hibernation Status Updated");
      onClose();
      window.location.reload();
    },
    onError: () => {
      toast.error(
        "There was error while updating status please try again later"
      );
    },
  });
  const handleSaveNewShift = (e) => {
    e.preventDefault();
  };
  const handlePageClick = (val) => {
    setGetListParams((prev) => {
      return {
        ...prev,
        page_number: val.selected,
      };
    });
  };
  const [shiftState, setShiftState] = useState({
    shift_id: "",
    user_id: id,
    // effect_from: '',
    start_date: "",
    end_date: "",
    current_active: false,
  });
  const handleChangeShift = (e) => {
    const { name, value } = e.target;
  
    if (name === "end_date") {
      // Check if the start date is set and validate the end date
      if (shiftState.start_date && value < shiftState.start_date) {
        return alert("End date should not be earlier than start date");
      }
    }
  
    if (name === "start_date") {
      // Check if the end date is set and validate the start date
      if (shiftState.end_date && value > shiftState.end_date) {
        return alert("Start date should not be later than end date");
      }
    }
  
    setShiftState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  
  const handleUpdateShift = () => {
    // const {shift_id,start_date} = shiftState
    // if(!shift_id || !start_date){
    //   return alert('Please Enter Shift and Effect From')
    // }
    submitHibernation.mutate(shiftState);
  };
  const handleEndHibernation = (start_date) => {
    const formatDate = (unixTimestamp) => {
      const date = new Date(unixTimestamp * 1000);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };
    setShowEndDate(true);
    setShowStartDate(false);
    setShiftState((prev) => ({
      ...prev,
      start_date: formatDate(start_date),
      end_date: new Date().toISOString().split("T")[0], 
    }));
    console.log(new Date().toISOString().split("T")[0]);
   
  };

  return (
    <>
      <Drawer anchor={"right"} open={open} onClose={onClose}>
        {submitHibernation.isLoading && (
          <div className=" fixed w-full h-full top-0 left-0 flex items-center justify-center bg-[#ffffffab]">
            <PrimaryLoader />
          </div>
        )}
        <div
          className="h-full bg-white p-8"
          style={{ width: "calc(100vw - 600px)" }}
        >
          <form className=" space-y-8" onSubmit={handleSaveNewShift}>
            <div>
              <div className="flex items-center justify-between space-x-3 pb-5 border-b border-gray-200">
                <div className="flex items-center space-x-3">
                  <ArrowLeftIcon
                    className=" text-gray-700 w-8 h-8 cursor-pointer"
                    onClick={onClose}
                  />
                </div>
                <div>
                  {mode !== "resigned" && (
                    <button
                      type="button"
                      onClick={handleUpdateShift}
                      className="px-5 py-2 bg-yellow-300 rounded font-semibold text-sm"
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-3 gap-x-5 py-5">
                {/* <div>
                  <div className=''>
                    <label className='label'>Current Shift: </label>
                    <span className='block font-semibold text-base bg-red-100 text-red-600 py-2 rounded-md text-center'>
                      {userHibernateListing?.data?.current_shift}
                    </span>
                  </div>
                </div> */}
                {/* <div>
                  <div className=''>
                    <label className='label'>Shift Name: </label>
                    <select disabled={mode === 'resigned'} name="shift_id"  onChange={handleChangeShift} className='input'>
                      <option value={shiftState.shift_id} >--Select Shift--</option>
                      {list.data?.data?.map((shiftData) => {
                        return <option key={shiftData.id} value={shiftData.id}>{shiftData.shiftName}</option>
                      })}
                    </select>
                  </div>
                </div> */}
                <div className="flex gap-3">
                
                  {/* <div>
                    <label className="label">Start Date</label>
                    <input
                      disabled={mode === "resigned"}
                      value={shiftState.start_date}
                      onChange={handleChangeShift}
                      type="date"
                      name="start_date"
                      className="input"
                    />
                  </div> */}
                   {!showStartDate && (
                    <div>
                      <label className="label">Start Date</label>
                      <input
                        disabled={mode === "resigned"}
                        value={shiftState.start_date}
                        onChange={handleChangeShift}
                        type="date"
                        name="start_date"
                        className="input"
                      />
                    </div>
                  )}
                  {showEndDate && (
                    <div>
                      <label className="label">End Date</label>
                      <input
                        disabled={mode === "resigned"}
                        value={shiftState.end_date}
                        onChange={handleChangeShift}
                        type="date"
                        name="end_date"
                        className="input"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <p className="text-gray-700 text-3xl font-semibold mb-4">
                  Previous Hibernation Data
                </p>
              </div>
              {userHibernateListing.data?.data.length <= 0 ? (
                <p className=" text-center font-semibold my-2">
                  No Previous Shifts
                </p>
              ) : (
                <>
                  <div className="max-w-full overflow-x-auto mt-4">
                    <table className="table-auto w-full">
                      <thead>
                        <tr className=" bg-gray-200 text-center">
                          {/* <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">Shift</th> */}
                          <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">
                            Start Date
                          </th>
                          <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">
                            End Date
                          </th>
                          {/* <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">Effected From</th> */}
                          <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">
                            Current Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userHibernateListing.data?.data.map((item) => {
                          return (
                            <tr key={item.id}>
                              {/* <td className="text-center text-dark font-medium text-xs py-2 px-2 bg-gray-50 border-b border-l border-gray-200">
                              {item.shiftInTime ? formatTime(item.shiftInTime) : '---'}
                            </td>
                            <td className="text-center text-dark font-medium text-xs py-2 px-2 bg-gray-50 border-b border-l border-gray-200">
                              {item.shiftOutTime ? formatTime(item.shiftOutTime) : '---'}
                            </td> */}
                              <td className="text-center text-dark font-medium text-xs py-2 px-2 bg-gray-50 border-b border-l border-gray-200">
                                {item.start_date ? (
                                  <Moment
                                    unix
                                    date={item.start_date}
                                    format="DD/MM/YYYY"
                                  />
                                ) : (
                                  "----"
                                )}
                              </td>
                              <td className="text-center text-dark font-medium text-xs py-2 px-2 bg-gray-50 border-b border-l border-gray-200">
                                {item.end_date ? (
                                  <Moment
                                    unix
                                    date={item.end_date}
                                    format="DD/MM/YYYY"
                                  />
                                ) : (
                                  "----"
                                )}
                              </td>
                              <td className="text-center text-dark w-full flex font-medium text-xs pl-[40%] py-2 px-2 bg-gray-50 border-b border-l border-gray-200">
                                
                                {item.current_active
                                  ? "In Hibernation"
                                  : "Hibernation Ended"}
                                 {item.end_date ? '': <button  onClick={() => handleEndHibernation(item.start_date)} className="justify-end ml-5 items-end  bg-black px-2 py-1 text-white text-xs rounded-md">End Hibernation</button>}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="px-5 mt-5">
                      <Pagination
                        currentPage={+paramsObject.page_number}
                        lengthofItems={userHibernateListing.data?.total_count}
                        limit={paramsObject.limit}
                        onPageChange={handlePageClick}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default HibernationDrawer;
