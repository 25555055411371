import React, { useRef, useState } from 'react'
import _, { set } from 'lodash'
import Layout from '../../components/layout/Layout'
import { Link, useNavigate } from "react-router-dom";
import spinner from '../../assets/images/spinner.gif'
import toast, { Toaster } from 'react-hot-toast';
import BonusForm from './BonusForm';
import { useForm } from 'react-hook-form';
import { config, TOKEN } from '../../config';
import Cookies from 'universal-cookie';
import axios from 'axios';
function AddBonus() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    let token = cookies.get(TOKEN);
    const [isFetchingEmployee,setIsFetchingEmployee] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { register, handleSubmit, getValues, formState: { errors } } = useForm({
        defaultValues: {
            from_date: null,
            to_date: null,
            title: null,
        }
    })
    const bonusTableRef = useRef(null)
const postForm = async (data, url) => {
    let token = cookies.get(TOKEN); // Make sure to replace 'TOKEN' with your actual token key
    var form_data = new FormData();

    for (var key in data) {
        if (Array.isArray(data[key])) {
            form_data.append(key, JSON.stringify(data[key])); // This handles user_data correctly
        } else {
            form_data.append(key, data[key]);
        }
    }

    try {
        var res = await axios({
            method: "POST",
            url: `${config.apiurl}${url}`, // Use the API URL provided in the parameter
            data: form_data,
            headers: { Authorization: token ? `Bearer ${token}` : '' }
        });
        
        return res;
    } catch (error) {
        console.error("API error:", error);
        throw error;
    }
};

    const handleSubmitEmployeDetail = async (data) => {
        setIsLoading(true)
        let user_data = bonusTableRef.current.getSelectedUser()
        let total_no_of_days = bonusTableRef.current.getTotalDays()
        let formattedData = {
            ...data,
            user_data,
            total_no_of_days
        }
        console.log(formattedData);

        // // connect api here for subbmiting the form
        try {
            // Call the API to submit the form data
            let response = await postForm(formattedData, '/create_bonus');
            console.log('API Response:', response.data);
            
            // Show success toast message
            toast.success('Bonus data saved successfully!');
            navigate(-1)
            // Reset loading state
            setIsLoading(false);

            // Optionally, you can refetch the employee list or redirect
            fetchEmployeeForBonus();
        } catch (error) {
            // Handle error, e.g., show error message
            toast.error('Failed to save bonus data');
            setIsLoading(false);
            console.error("Error submitting form:", error);
        }
    }

    const fetchEmployeeForBonus = () => {
        bonusTableRef.current.fetchBonusUserListing()
    }



    return (
        <Layout>
            <Toaster />
            <div className="">
                <div className=" container px-6">
                    <div className="py-4 text-start">
                        <Link to={"/bonus"} className=" bg-[color:var(--color1)] text-white rounded py-2 px-5">Back</Link>
                    </div>
                    <small className=' py-4'><span className=' mandatory'>*</span> Fields are mandatory</small>
                    <form action="" method="POST" onSubmit={handleSubmit(handleSubmitEmployeDetail)} className='mt-10'>
                        <div className=" ">
                            <div className="space-y-3">
                                <div className="flex items-center justify-between">
                                    <h1 className="text-2xl font-semibold">Bonus Details</h1>
                                    <button className=' px-4 py-2 rounded bg-[color:var(--color1)] text-white'>Save Bonus Data</button>
                                </div>

                                <div className="grid grid-cols-2 gap-4 personalDetail-section">
                                    <div>
                                        <label htmlFor="title" className="label">
                                            Title:<span className="mandatory">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register('title', { required: 'Title is required' })}
                                            className="input"
                                            placeholder="Enter Title"
                                        />
                                        {errors.title && <small className=' text-red-700'>{errors.title.message}</small>}
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 items-end'>
                                        <div>
                                            <label htmlFor="from_date" className="label">
                                                From Date:<span className="mandatory">*</span>
                                            </label>
                                            <input
                                                type="date"
                                                {...register('from_date', { required: 'From Date is required' })}
                                                className="input"
                                            />
                                            {errors.from_date && <small className=' text-red-700'>{errors.from_date.message}</small>}
                                        </div>
                                        <div>
                                            <label htmlFor="to_date" className="label">
                                                To Date:<span className="mandatory">*</span>
                                            </label>
                                            <input
                                                type="date"
                                                {...register('to_date', { required: 'To Date is required' })}
                                                className="input"
                                            />
                                            {errors.to_date && <small className=' text-red-700'>{errors.to_date.message}</small>}
                                        </div>
                                        <div>
                                            <button disabled={isFetchingEmployee} onClick={handleSubmit(fetchEmployeeForBonus)} type='button' className='bg-[color:var(--color1)]    rounded-md p-3 text-white font-semibold'>{isFetchingEmployee ? "Fetching...." : "Fetch Employee"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=' my-5 border-t border-gray-200'>
                                {/* <p className='text-lg font-semibold'> Total no. of days : {bonusTableRef.current.getTotalDays()}</p> */}
                              
                                <BonusForm ref={bonusTableRef} getValues={getValues} setIsFetchingEmployee={setIsFetchingEmployee}/>
                            </div>
                            {/* {isLoading ? <div className=' w-14 h-14 mx-auto my-8'>
                                    <img src={spinner} alt="spinner" className='w-full h-full' />
                                </div> : <div className=" text-center my-8">
                                    <button type='submit' className=' bg-[color:var(--color1)] hover:bg-[color:var(--color12)] text-white py-3 px-20 rounded-full'>Submit</button>
                                </div>} */}
                        </div>
                    </form>
                </div>
            </div>

            {/* <DialogMessage open={empCreateError} type={"Error"} message={empCreateError === "Required Fields" ? "Some fields are missing" : empCreateError} handleClose={() => setEmpCreateError('')} /> */}
        </Layout>
    )
}

export default AddBonus