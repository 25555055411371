import { XIcon, ArrowLeftIcon } from "@heroicons/react/solid";
import { Modal } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import spinner from "../../assets/images/spinner.gif";
import { downloadReport } from "../../api";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../components/pagination";
// import BulkSalaryModal from '../../../components/salary/BulkSalaryModal'
import { getAttendanceReportList } from "../../api/employeeDetails";
import Layout from "../../components/layout/Layout";
import DownloadAttendanceReport from "./DownloadAttendanceReport";
import FetchFile from "./FetchFile";
import { config } from "../../config";
import useDownloadFile from "../../hooks/useDownloadFile";
import toast from "react-hot-toast";

const getDateMonthString = (date) => {
  let tempDate = new Date(date);
  let month = tempDate.getMonth() + 1;
  return tempDate.getFullYear() + "-" + month;
};

function AttendanceReport() {
  const [filename, setFilename] = useState("");

  const [absentError, setAbsentError] = useState(null);
  const [punchOutError, setPunchOutError] = useState(null);
  const [workingHourError, setWorkingHourError] = useState(null);
  const [prePostError, setPrePostError] = useState(null);

  const [hasAbsentError, setHasAbsentError] = useState(false);
  const [hasPunchOutError, setHasPunchOutError] = useState(false);
  const [hasWorkingHourError, setHasWorkingHourError] = useState(false);
  const [hasPrePostError, setHasPrePostError] = useState(false);

  const handleAbsentError = useCallback(() => {
    if (!hasAbsentError) {
      toast.error("No absent details");
      setHasAbsentError(true);
    }
  }, [hasAbsentError]);

  const handlePunchOutError = useCallback(() => {
    if (!hasPunchOutError) {
      toast.error("No punchout details");
      setHasPunchOutError(true);
    }
  }, [hasPunchOutError]);
  const handleWorkingHourError = useCallback(() => {
    if (!hasWorkingHourError) {
      toast.error("No less working hour details");
      setHasWorkingHourError(true);
    }
  }, [hasWorkingHourError]);
  const handlePrePostError = useCallback(() => {
    if (!hasPrePostError) {
      toast.error("No pre post shift details");
      setHasPrePostError(true);
    }
  }, [hasPrePostError]);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let initialData = {
    month_year: searchParams.get("month_year")
      ? new Date(searchParams.get("month_year"))
      : new Date(),
    // prev_month_year: new Date(),
    page_number:  0,
    limit: searchParams.get("limit") || 20,
    search_query: searchParams.get("search_query") || "",
  };

  const [filterData, setFilterData] = useState(initialData);
  const [isFetching, setIsFetching] = useState(false);
  const [salaryList, setSalaryList] = useState([]);
  const [count, setCount] = useState("");
  const [companyPayout, setCompanyPayout] = useState([]);

  const handleDate = (value, name) => {
    let data = {
      ...filterData,
      page_number:0,
      [name]: value,
    };
    console.log(data);
    
    setFilterData(data);
    handleCalculateSalary(data);
    setSearchParams(data);
  };
  const handleCalculateSalary = (data) => {
    const temp = {
      ...data,
      month_year: data?.month_year,
      page_number: filterData?.page_number,
      search_query: filterData.search_query,
      limit: filterData.limit,
    };
    console.log(temp,"temp");
    
    setIsFetching(true);
    getAttendanceReportList(temp)
      .then((res) => {
        if (res.status === 200) {
          setSalaryList(res?.data.data);
          setCompanyPayout(res?.data.company_payout);
          setCount(res?.data.total_count);
          setIsFetching(false);
          scrollToTop();
        }
      })
      .catch((err) => {
        alert("Something went wrong!");
        setIsFetching(false);
      });
  };

  const handleDownloadReport = () => {
    const temp = {
      ...filterData,
      month_year: getDateMonthString(filterData["month_year"]),
    };
    downloadReport(temp)
      .then((res) => {
        // console.log(res)
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const d = new Date();
        let fileName = `Salary_Report_${
          months[d.getMonth()]
        }_${d.getFullYear()}.xlsx`;
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {});
  };
  // Handle clicking on the download links
  const { reportDownload: downloadAbsentReport } = useDownloadFile({
    url: "employee_absent_report_api",
    filename:filename,
    onError: () => {
        handleAbsentError();
        setHasAbsentError(false); // Reset error state
      },
  });

  const { reportDownload: downloadPunchOutReport } = useDownloadFile({
    url: "employee_no_punch_out_report_api",
    filename,
    onError: () => {
        handlePunchOutError();
        setHasPunchOutError(false); // Reset error state
      },
  });
  const { reportDownload: downloadWorkingLessReport } = useDownloadFile({
    url: "employee_working_less_then_shift_hours_report_api",
    filename,
    onError: () => {
      handleWorkingHourError();
        setHasWorkingHourError(false); // Reset error state
      },
  });
  const { reportDownload: downloadPrePostReport } = useDownloadFile({
    url: "employee_pre_post_shift_OT_report_api",
    filename,
    onError: () => {
      handlePrePostError();
        setHasPrePostError(false); // Reset error state
      },
  });

  const handleDownloadAbsentReport = (file_date) => {
    // console.log(file_date);
    console.log("File Date:", file_date); // Log to verify if it's undefined or incorrect
    const reportFilename = `Absent Report for ${file_date}.xlsx`;
      setFilename(reportFilename); // Update the filename state
    downloadAbsentReport.mutate({
      from_date: file_date,
      // filename: `absent_report_${file_date}.xlsx`,
    });
  };

  const handleDownloadPunchOutReport = (file_date) => {
    const reportFilename = `No Punchout Report for ${file_date}.xlsx`;
    setFilename(reportFilename); // Update the filename state
    downloadPunchOutReport.mutate({
      from_date: file_date,
      // filename: `no_punch_out_report_${file_date}.xlsx`,
    });
  };
  const handleDownloadWorkingLessReport = (file_date) => {
    const reportFilename = `Less Working Hour Report for ${file_date}.xlsx`;
    setFilename(reportFilename); // Update the filename state
    downloadWorkingLessReport.mutate({
      from_date: file_date,
      // filename: `less_working_hour_report_${file_date}.xlsx`,
    });
  };
  const handleDownloadPrePostReport = (file_date) => {
    const reportFilename = `Pre Post Report for ${file_date}.xlsx`;
    setFilename(reportFilename); // Update the filename state
    downloadPrePostReport.mutate({
      from_date: file_date,
      // filename: `pre_post_shift_report_${file_date}.xlsx`,
    });
  };


  useEffect(() => {
    handleCalculateSalary(filterData);
  }, [filterData.page_number, filterData.search_query]);

  const handleNavigateToEmployeeDetail = (id, username) => {
    // navigate(`/employee-salary-details/${id}/${username}`)
    navigate(`/employee-salary-details/${id}`);
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    let data = {
      ...filterData,
      [name]: value,
    };
    setFilterData(data);
    setSearchParams(data);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };
  const handlePageClick = (val) => {
    let data = {
      ...filterData,
      page_number: val.selected,
    };
    setFilterData(data);
    setSearchParams(data);
  };
  const [isImportModalOpen, setIsModalOpen] = useState(false);
  const [isOpenReportModal, setOpenReportModal] = useState(false);

  return (
    <>
      {isFetching && (
        <div className=" w-full h-full fixed top-0 left-0 flex items-center justify-center bg-[#ffffffbf] z-50">
          <img src={spinner} alt="spinner" className="w-20 h-20" />
        </div>
      )}
      <Layout>
        <div className=" mt-14 mb-20">
          <div className="">
            <form className="flex items-end justify-between px-5">
              <div className="flex items-end space-x-4">
                <div>
                  <input
                    type="search"
                    placeholder="Search By Name..."
                    value={filterData?.search_query}
                    name="search_query"
                    className="input"
                    onChange={handleSearch}
                  />
                </div>
                <div className="min-w-[10rem]  basis-[35%]">
                  <label className=" roboto label">Select Month/Year</label>
                  <DatePicker
                    required
                    name="month_year"
                    autoComplete="false"
                    className="input"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selected={filterData?.month_year}
                    onChange={(value) => handleDate(value, "month_year")}
                  />
                </div>
              </div>
              <div className="px-4">
                <button
                  type="button"
                  onClick={() => setOpenReportModal(true)}
                  className=" bg-[color:var(--color1)] text-white  p-2 rounded-md font-semibold text-xs"
                >
                  Fetch File
                </button>
              </div>
            </form>
          </div>
          {salaryList?.length > 0 ? (
            <>
              <div className="max-w-full overflow-x-auto px-4 mt-8">
                <table className="table-auto w-full">
                  <thead>
                    <tr className=" bg-[color:var(--color1)] text-center">
                      {/* <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th> */}
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        File Date
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        File
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        Absent Count
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        No Punch out Count
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        Working less then shift Count
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Pre Post shift Count
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {salaryList?.map((data, idx) => {
                      const {
                        file_date,
                        status,
                        absent_count,
                        no_punch_out_count,
                        id,
                        file_path,
                        work_less_shift_count,
                        pre_post_ot_count
                      } = data;
                      return (
                        <tr
                          key={idx}
                          className="bg-gray-200 hover:bg-gray-100 "
                        >
                          {/* <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">
                                                    {(filterData.limit * (parseInt(filterData.page_number) + 1)) - (filterData.limit - (idx + 1))}
                                                </td> */}
                          <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">
                            {file_date}
                          </td>
                          <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8] capitalize ">
                            <a className="underline" href={file_path}>
                              Download file
                            </a>
                          </td>
                          <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                            {status === "UNDER PROCESS" ? (
                              <span className="text-gray-400 font-semibold">
                                {absent_count}
                              </span>
                            ) : absentError ? (
                              <span className="text-red-500 font-semibold">
                                {absentError}
                              </span>
                            ) : (
                              <button
                                onClick={() => handleDownloadAbsentReport(file_date)} // Trigger API call on click
                                className="text-blue-500 font-semibold hover:underline"
                              >
                                {absent_count}
                              </button>
                            )}
                          </td>
                          <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                            {status === "UNDER PROCESS" ? (
                              <span className="text-gray-400 font-semibold">
                                {no_punch_out_count}
                              </span>
                            ) : punchOutError ? (
                              <span className="text-red-500 font-semibold">
                                {punchOutError}
                              </span>
                            ) : (
                              <button
                                onClick={() => handleDownloadPunchOutReport(file_date)} // Trigger API call on click
                                className="text-blue-500 font-semibold hover:underline"
                              >
                                {no_punch_out_count}
                              </button>
                            )}
                          </td>
                          <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                            {status === "UNDER PROCESS" ? (
                              <span className="text-gray-400 font-semibold">
                                {work_less_shift_count}
                              </span>
                            ) : workingHourError ? (
                              <span className="text-red-500 font-semibold">
                                {workingHourError}
                              </span>
                            ) : (
                              <button
                                onClick={() => handleDownloadWorkingLessReport(file_date)} // Trigger API call on click
                                className="text-blue-500 font-semibold hover:underline"
                              >
                                {work_less_shift_count}
                              </button>
                            )}
                          </td>
                          <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                            {status === "UNDER PROCESS" ? (
                              <span className="text-gray-400 font-semibold">
                                {pre_post_ot_count}
                              </span>
                            ) : punchOutError ? (
                              <span className="text-red-500 font-semibold">
                                {punchOutError}
                              </span>
                            ) : (
                              <button
                                onClick={() => handleDownloadPrePostReport(file_date)} // Trigger API call on click
                                className="text-blue-500 font-semibold hover:underline"
                              >
                                {pre_post_ot_count}
                              </button>
                            )}
                          </td>
                          <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8]">
                            {status}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="px-5 mt-5">
                <Pagination
                  currentPage={+filterData.page_number}
                  lengthofItems={count}
                  limit={filterData.limit}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          ) : (
            <p className=" text-center font-semibold text-lg mt-10">
              No Employee Found
            </p>
          )}
        </div>
      </Layout>
      <FetchFile
        data={filterData}
        open={isOpenReportModal}
        handleClose={() => setOpenReportModal(false)}
      />
      {/* <BulkSalaryModal open={isImportModalOpen} handleClose={() => setIsModalOpen(false)}/> */}
    </>
  );
}

export default AttendanceReport;
