import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import { ArrowLeftIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import LikertView from '../../components/likert';
import usePut from '../../hooks/usePut'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/Loader';

const AdvanceShiftEditing = ({ open, onClose, shiftData }) => {
  const weekendObject = {
    sunday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    monday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    tuesday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    wednesday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    thursday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    friday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    saturday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ]
  }
  let formData = {
    shiftName: '',
    shiftInTime: '',
    shiftOutTime: '',
    shiftGraceInTime: '',
    shiftGraceOutTime: '',
    halfDayTime: '',
    absentTime: '',
    half_day_in_time: '',
    half_day_out_time: '',
    halfDayInTime: '',
    halfDayOutTime: '',
    weekendDefinition: weekendObject,
    compulsory_ot: {
      isEnabled: false,
      shiftOTStartTime: '',
      shiftOTEndTime: ''
    },
    half_day_grace: {
      isEnabled: false,
      half_day_grace_out_time: ''
    },
    late_penalty: false,
    weekend_in_time: '',
    weekend_out_time: '',
    same_weekend_rate: false
  }
  const [shiftFormData, setShiftFormData] = useState(formData)
  const [isOtCompulsary, setIsOtCompulsary] = useState(false)
  useEffect(() => {
    if (shiftData) {
      const { weekendDefinition } = shiftData || {}
      let newWeekendDefinition = JSON.parse(JSON.stringify(weekendObject))
      Object.keys(newWeekendDefinition).forEach((weekEndKey) => {
        if (weekEndKey in weekendDefinition) {
          weekendDefinition[weekEndKey].forEach((weekEndData, idx) => {
            if (newWeekendDefinition[weekEndKey][idx].type === +weekEndData.type || +weekEndData.type === NaN) {
              newWeekendDefinition[weekEndKey][idx] = weekEndData
            }
          })
        }
      })
      let data = {
        ...shiftData,
        weekendDefinition: newWeekendDefinition
      }
      setShiftFormData(data)
    }
  }, [shiftData])
  const handleSetShiftFormData = (e) => {
    let name = e.target.name
    let value = e.target.value
    let prevShiftData = shiftFormData
    prevShiftData[name] = value
    setShiftFormData({ ...shiftFormData })
  }
  const handleUpdateWeekendDefinition = (object) => {
    let prevShiftData = shiftFormData
    prevShiftData.weekendDefinition = object
    setShiftFormData({ ...shiftFormData })
  }
  const { handleAuth } = usePut({
    url: 'shift_create',
    refreshUrl: 'shift_listing',
    onSuccess: () => {
      toast.success("Shift Created Done!", {
        position: 'top-right'
      });
      setTimeout(() => {
        window.location.reload()
      }, 1200)
    },
    onError: () => {
      toast.error("There Was An Error While Creating Shift, Try Again Later", {
        position: 'bottom-right',
      });
    }
  })
  const handleSubmitShiftData = (e) => {
    e.preventDefault()
    handleAuth.mutate(shiftFormData)
  }
  const handleCloseDialog = () => {
    clearData()
    onClose()
  }
  const clearData = () => {
    setShiftFormData(formData)
  }

  const handleChangeIsOt = (e) => {
    let checked = e.target.checked
    setIsOtCompulsary(checked)
    setShiftFormData(prev => {
      return {
        ...prev,
        compulsory_ot: {
          ...prev.compulsory_ot,
          isEnabled: checked
        }
      }
    })
  }
  const handleChangeIsHalfDayGrace = (e) => {
    let checked = e.target.checked
    setShiftFormData(prev => {
      return {
        ...prev,
        half_day_grace: {
          ...prev.half_day_grace,
          isEnabled: checked
        }
      }
    })
  }
  const handleChangeCompOt = (e) => {
    let name = e.target.name
    let value = e.target.value
    setShiftFormData((prev) => {
      return {
        ...shiftFormData,
        compulsory_ot: {
          ...shiftFormData.compulsory_ot,
          [name]: value
        }
      }
    })
  }
  const handleChangeHalfDayGraceTime = (e) => {
    let name = e.target.name
    let value = e.target.value
    setShiftFormData((prev) => {
      return {
        ...shiftFormData,
        half_day_grace: {
          ...shiftFormData.half_day_grace,
          [name]: value
        }
      }
    })
  }
  const handleChangeLateFee = (e) => {
    setShiftFormData(prev => {
      return {
        ...prev,
        late_penalty: e.target.checked
      }
    })
  }

  const handleCheckboxChange = (e) => {
    let checked = e.target.checked
    setShiftFormData(prev => {
      return {
          ...prev,
          same_weekend_rate: checked
      }
    })
  }
  return (
    <Drawer anchor={'right'} open={open} onClose={handleCloseDialog}>
      <div className='h-full bg-white px-8' style={{ width: 'calc(100vw - 250px)' }}>
        <form className=' space-y-8' onSubmit={handleSubmitShiftData}>
          <div className='flex items-center justify-between  py-5 border-b border-gray-200 sticky z-40 top-0 bg-white'>
            <div className='flex items-center space-x-3'>
              <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={handleCloseDialog} />
              <p className='text-gray-700 text-3xl font-semibold'>Shift Details</p>
            </div>
            <button disabled={handleAuth.isLoading} className=' bg-color1 px-5 py-2 text-lg text-white rounded-md'>
              <div className='flex items-center justify-center space-x-2'>
                <span>
                Save Shift
                </span>
                {handleAuth.isLoading &&  <Loader/>}
              </div>
            </button>
          </div>
          <div className=' '>
            <div className='grid grid-cols-3 gap-5 py-5'>
              <div>
                <div className=''>
                  <label className='label'>Shift Name: </label>
                  <input type="text" name='shiftName' value={shiftFormData.shiftName} onChange={handleSetShiftFormData} required={true} className='input' placeholder='Enter Shift name ' />
                </div>
              </div>
              <div>
                <div>
                  <label className='label'>Shift In-Time: </label>
                  <input type="time" name='shiftInTime' value={shiftFormData.shiftInTime} onChange={handleSetShiftFormData} required={true} className='input' />
                </div>
              </div>
              <div>
                <div>
                  <label className='label'>Shift Out-Time: </label>
                  <input type="time" name='shiftOutTime' required={true} value={shiftFormData.shiftOutTime} onChange={handleSetShiftFormData} className='input' />
                </div>
              </div>
              <div>
                <label className='label'>Shift Grace In-Time: <small className='text-red-600 text-xs'>{`*(Enter Number in Minute)`}</small></label>
                <div className='flex items-center space-x-3'>
                  <input type="number" placeholder='*15' value={shiftFormData.shiftGraceInTime} onChange={handleSetShiftFormData} name='shiftGraceInTime' className='input basis-[20%] text-lg' />
                  <p className='font-semibold' >Minutes</p>
                </div>
              </div>
              <div>
                <label className='label'>Shift Grace Out-Time: <small className='text-red-600 text-xs'>{`*(Enter Number in Minute)`}</small></label>
                <div className='flex items-center space-x-3'>
                  <input type="number" placeholder='*15' value={shiftFormData.shiftGraceOutTime} onChange={handleSetShiftFormData} name='shiftGraceOutTime' className='input basis-[20%] text-lg' />
                  <p className='font-semibold' >Minutes</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className='flex items-center space-x-4'>
                <div className='space-x-2 mt-4'>
                  <label htmlFor="compulsoryOT" className='label text-lg' >
                  <input type="checkbox" id="compulsoryOT" name='compulsoryOT' checked={shiftFormData.compulsory_ot.isEnabled} onChange={handleChangeIsOt} />
                    <span className=' inline-block ml-2'>Compulsory OT</span>
                  </label>
                </div>
              </div>
            </div>
            {shiftFormData.compulsory_ot.isEnabled &&
              <div className='space-x-24 flex'>
                <div>
                  <label className='label'>OT Start-Time: </label>
                  <input type="time" name='shiftOTStartTime' value={shiftFormData?.compulsory_ot?.shiftOTStartTime} onChange={handleChangeCompOt} required={true} className='input' />
                </div>
                <div>
                  <label className='label'>OT End-Time: </label>
                  <input type="time" name='shiftOTEndTime' value={shiftFormData?.compulsory_ot?.shiftOTEndTime} onChange={handleChangeCompOt} required={true} className='input' />
                </div>
              </div>}
          </div>
          <div>
            <div>
              <div className='flex items-center space-x-4'>
                <div className='space-x-2 mt-4'>
                  <label htmlFor="halfDayGrace" className='label text-lg' >
                  <input type="checkbox" id="halfDayGrace" name='halfDayGrace' checked={shiftFormData.half_day_grace.isEnabled} onChange={handleChangeIsHalfDayGrace} />
                    <span className=' inline-block ml-2'>Half Day Grace</span>
                  </label>
                </div>
              </div>
            </div>
            {shiftFormData.half_day_grace.isEnabled &&
              <div className='space-x-24 flex'>
                <div>
                  <label className='label'>Half Day grace out time: </label>
                  {/* <input type="time" name='half_day_grace_out_time' value={shiftFormData?.half_day_grace?.half_day_grace_out_time} onChange={handleChangeHalfDayGraceTime} required={true} className='input' /> */}
                  <div className='flex items-center space-x-3'>
                  <input type="number" placeholder='*15' value={shiftFormData?.half_day_grace?.half_day_grace_out_time} onChange={handleChangeHalfDayGraceTime} required={true} name='half_day_grace_out_time' className='input basis-[20%] text-lg' />
                  <p className='font-semibold' >Minutes</p>
                </div>
                </div>
              </div>}
          </div>
          <div>
            <p className='text-gray-700 text-3xl font-semibold mb-4'>Shift Half Day Margin</p>
            <div className='grid grid-cols-3 gap-x-5'>
              <div>
                <div>
                  <label className='label'>Half In Time: </label>
                  <input type="time" name='halfDayInTime' value={shiftFormData.halfDayInTime} onChange={handleSetShiftFormData} className='input' />
                </div>
              </div>
              <div>
                <div>
                  <label className='label'>Half Out Time: </label>
                  <input type="time" name='halfDayOutTime' value={shiftFormData.halfDayOutTime} onChange={handleSetShiftFormData} className='input' />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='flex items-center space-x-4'>
              <div className='space-x-2 mt-4' >
                <label htmlFor="Penalty" className='label text-lg' >
                <input type="checkbox" id="Penalty" className='ml-2' name='compulsoryOT' checked={shiftFormData.late_penalty}  onChange={handleChangeLateFee}/>
                  <span className=' inline-block ml-2'>
                    Late Penalty Applicable?
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className='py-5'>
            <div className=' grid grid-cols-4 gap-x-4 mb-4 items-end'>
              <div>
                <div>
                  <label className='label'>Weekend In-Time: </label>
                  <input type="time" name='weekend_in_time' value={shiftFormData.weekend_in_time} onChange={handleSetShiftFormData} required={true} className='input' />
                </div>
              </div>
              <div>
                <div>
                  <label className='label'>Weekend Out-Time: </label>
                  <input type="time" name='weekend_out_time' required={true} value={shiftFormData.weekend_out_time} onChange={handleSetShiftFormData} className='input' />
                </div>
              </div>
              <div>
                <div>
                  <label className=' space-x-2'>
                    <input
                      type="checkbox"
                      checked={shiftFormData.same_weekend_rate}
                      onChange={handleCheckboxChange}
                    />
                    <span>
                      Same Weekend Rate
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <p className='text-gray-700 text-3xl font-semibold mb-4'>Weekend Definition</p>
            <LikertView
              onChange={(values) => handleUpdateWeekendDefinition(values)}
              values={shiftFormData.weekendDefinition}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </Drawer>
  )
}

export default AdvanceShiftEditing