import React, { useEffect, useState } from 'react'
import { BriefcaseIcon, CalendarIcon, CashIcon, ClipboardIcon, DocumentTextIcon, PresentationChartBarIcon, SwitchHorizontalIcon, UserGroupIcon, LoginIcon, IdentificationIcon } from '@heroicons/react/solid'
import { NavLink } from "react-router-dom";
import Cookies from 'universal-cookie';
import { PROJECT_NAME } from '../config';
import NavbarDropdown from './dropdown/navbarDropdown';

const cookies = new Cookies

function Sidebar({ show }) {
  const [role, setRole] = useState('')
  useEffect(() => {
    setRole(cookies.get("_am_role"))
  }, [])
  const [moreMenuOpen, setMoreMenuOpen] = useState(null)
  const handleOpenMoreMenu = (value) => {
    setMoreMenuOpen(value)
  }

  const handleCloseMoreMenu = () => {
    setMoreMenuOpen(false)
  }
  const [attendanceMenuOpen, setAttendanceMenuOpen] = useState(null)
  const handleOpenAttendanceMenu = (value) => {
    setAttendanceMenuOpen(value)
  }

  const handleCloseAttendanceMenu = () => {
    setAttendanceMenuOpen(false)
  }
  const SHIFT = [
    {
      name: 'Shifts Creation',
      slug: '/shifts',
    },
    {
      name: 'Temporary Shift Mapping',
      slug: '/employee-shift-mapping',
    },
    // {
    //   name: 'Shifts Rotation',
    //   slug: '/shift-rotation',
    // },
    // {
    //   name: 'Settings',
    //   slug: '/shift-settings',
    // },
  ]
  const ATTENDANCE = [
    {
      name: 'Attendance Listing',
      slug: '/attendance',
    },
    {
      name: 'Attendance Report',
      slug: '/attendance-report',
    },
  ]
  return (
    <>

      <div className={` hidden   lg:block bg-[color:var(--color1)] border-r border-[color:var(--color1)] w-64 h-screen overflow-y-scroll   p-4 fixed ${show ? 'active' : ''}`}>
        <div className='' >
          <div>
            <p className=' text-center text-3xl text-white font-bold mb-10 roboto mt-4'><NavLink to={"/"}>{PROJECT_NAME}</NavLink></p>
          </div>
          <div className='divide-slate-400 divide-y-[1px]'>
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/employe-details'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <BriefcaseIcon className='w-6' />
                <span>User Details</span>
              </NavLink>
            </div>}
            {/* {role === 'employee' || role === 'consultant' ? <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/leave-application'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <DocumentTextIcon className=' w-6' />
                <span>Leave Application</span>
              </NavLink>
            </div> : null} */}
            {/* {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/appointment'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <ClipboardIcon className='w-6' />
                <span>Appointment Letter</span>
              </NavLink>
            </div>} */}
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/leave-applied'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <ClipboardIcon className='w-6' />
                <span>Leaves Applied</span>
              </NavLink>
            </div>}
            {/* {(role === 'employee' || role === 'consultant') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/leaves'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <ClipboardIcon className='w-6' />
                <span>Leaves Applied</span>
              </NavLink>
            </div>} */}
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/salary'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <CashIcon className='w-6' />
                <span>Salary</span>
              </NavLink>
            </div>}
            {/* {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/web-checkin'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <LoginIcon className='w-6' />
                <span>Web Check-in</span>
              </NavLink>
            </div>} */}
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2 relative cursor-pointer">
              <div onClick={() => handleOpenMoreMenu('shift')} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <SwitchHorizontalIcon className='w-6' />
                <span>Shifts</span>
              </div>
              <NavbarDropdown menuData={SHIFT} open={moreMenuOpen === 'shift'} onClose={handleCloseMoreMenu}/>
            </div>}
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/public-holidays'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <CalendarIcon className='w-6' />
                <span>Public Holidays</span>
              </NavLink>
            </div>}
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2 relative cursor-pointer">
              <div onClick={() => handleOpenAttendanceMenu('attendance')} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <SwitchHorizontalIcon className='w-6' />
                <span>Attendance</span>
              </div>
              <NavbarDropdown menuData={ATTENDANCE} open={attendanceMenuOpen === 'attendance'} onClose={handleCloseAttendanceMenu}/>
            </div>}
            {/* {role === 'company' && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/attendance'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <PresentationChartBarIcon className='w-6' />
                <span>Attendance</span>
              </NavLink>
            </div>} */}
            {role === 'company' && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/web-check-ins'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
              <IdentificationIcon className='w-6' />
                <span>Web Check-ins</span>
              </NavLink>
            </div>}
            {role === 'company' && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/department'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <UserGroupIcon className='w-6' />
                <span>Department</span>
              </NavLink>
            </div>}
            {role === 'company' && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/form16'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <UserGroupIcon className='w-6' />
                <span>Form 16</span>
              </NavLink>
            </div>}
            {role === 'company' && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/bonus'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <UserGroupIcon className='w-6' />
                <span>Bonus</span>
              </NavLink>
            </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar