import Modal from '@mui/material/Modal';
import useReadWithGet from '../../hooks/read/useReadWithGetMethod';
import SelectSearch from 'react-select-search';
import { useState } from 'react';
import useDownloadFile from '../../hooks/useDownloadFile';
import Loader from '../../components/Loader';
import toast, { Toaster } from 'react-hot-toast';

const DownloadAttendanceReport = ({ open, handleClose }) => {
  let initialDepartmentState = {
    limit: 50,
    searchQuery: '',
  }
  let reportState = {
    from_date: '',
    to_date: '',
    department_id: ''
  }
  const [report, setReportState] = useState(reportState)
  const handleChangeReportState = (e) => {
    let value = e.target.value
    let name = e.target.name
    setReportState(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }
  const handleSetDepartment = (value) => {
    setReportState(prev => {
      return {
        ...prev,
        department_id: value
      }
    })
  }
  const [departOption, setDepartOption] = useState([])
  useReadWithGet({
    url: "department_listing",
    initialData: initialDepartmentState,
    onSuccess: (res) => {
      let data = res.data.map(({ id, name }) => ({ value: id, name: name }))
      setDepartOption(data);
    }
  })
  const { reportDownload } = useDownloadFile({
    url: 'attendance_report',
    filename: 'attendance-report.xlsx',
    onError: () => {
      toast.error('There was an error while downloading the file')
    }
  })
  const handleDownloadReport = (e) => {
    e.preventDefault()
    reportDownload.mutate(report)
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div className=' bg-white w-[40%] rounded-md absolute left-1/2 -translate-x-1/2 top-6'>
        <div>
          <h1 className=' font-semibold text-xl px-4 py-3'>Attendance Report</h1>
          <form className='px-4 py-4 space-y-4' onSubmit={handleDownloadReport}>
            <div className="grid grid-cols-2 gap-4">
              <div className="">
                <label htmlFor="total_leave" className='label'>From Date:<span className='mandatory'>*</span></label>
                <input type="date" required name='from_date' className='input disabled:cursor-not-allowed' onChange={handleChangeReportState} value={report.from_date} />
              </div>
              <div className="">
                <label htmlFor="total_leave" className='label'>To Date:<span className='mandatory'>*</span></label>
                <input type="date" required name='to_date' className='input disabled:cursor-not-allowed' onChange={handleChangeReportState} value={report.to_date} />
              </div>
            </div>
            <div className="">
              <label htmlFor="total_leave" className='label'>Department:</label>
              <SelectSearch
                onChange={handleSetDepartment}
                search
                options={departOption}
                name="department"
                placeholder="Select department" />
            </div>
            <div className=' space-x-4 text-center'>
              <button disabled={reportDownload.isLoading} className=' bg-blue-200 text-blue-700 font-semibold px-4 py-2 rounded'>
                <div className=' flex items-center justify-center space-x-3'>
                  <span>Download Report</span>
                  {reportDownload.isLoading && <Loader className={'text-blue-700'}/>}
                </div>
              </button>
              <button type='button' onClick={handleClose} className=' border font-semibold px-4 py-2 rounded'>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default DownloadAttendanceReport